import React, { useState, useEffect,  forwardRef, useImperativeHandle, useRef  } from 'react'
import { Button, Col, Row, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader
} from 'react-bs-datatable';
import SparkApi from "../api/SparkApi";
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import Confirm from './Confirm';
import PaymentEdit from './PaymentEdit';

const PaymentRelatedList = ({parent, refreshPaymentList, onPaymentListUpdate}) => {


  const [modalShow, setModalShow] = React.useState(false);
  const [payment, setPayment] = React.useState('');
  const [modalShowpayment, setModalShowpayment] = React.useState(false);
  const [modalShowpaymentView, setModalShowpaymentView] = React.useState(false);

  const [body, setBody] = useState([]);


  useEffect(() => {
    paymentList();
    
  }, [refreshPaymentList]);
  
  const paymentList = () => {
    async function init() {
      let payments = await SparkApi.fetchPayments(parent.id);
    ////('payments',payments)
      ////(payments);
      if (payments && payments?.length > 0) {
        setBody(payments);
        let total = 0;
        payments.map((item) =>{
          total += parseFloat(item.amount);
        })
        onPaymentListUpdate(total)
      } else {
        setBody([]);
        onPaymentListUpdate(0)
      }
    }
    init();
  }
  const handleDelete = (row) => {
    setModalShow(true);
    setPayment(row);
  }

  const labels = {
    beforeSelect: " "
  }

  const deletepayment = async () => {
    const result = await SparkApi.deletePayment(payment.id);
    ////('result',result)
    if (result.success) {
        setPayment('');
      setModalShow(false);
      paymentList();
    }
  }

  const submitpayments = () => {
    setModalShowpayment(false);
    paymentList();
  }

  const editPayment = (row) => {
    setModalShowpayment(true)
    setPayment(row);
  }


  

  const header = [
    { title: 'Amount', prop: 'amount',cell: (row) => (
        <Link
        onClick={() => editPayment({ row })}
         
        >
          ₹ {row.amount}
        </Link>
      ) },
    { title: 'Payment Date', prop: 'paymentdate', cell: (row) => (moment(row.paymentdate).format('DD-MM-YYYY')) },
    { title: 'Payment Mode', prop: 'type' },
    { title: 'Remark', prop: 'discription' },
    {
      title: 'Actions', prop: 'id', cell: (row) => (
        <><Button className='btn-sm mx-2' onClick={() => editPayment({ row })}><i className="fa-regular fa-pen-to-square"></i></Button>
          <Button className='btn-sm mx-2' variant='danger' onClick={() => handleDelete(row)}><i class="fa-regular fa-trash-can"></i></Button>
        </>
      )
    }
  ];


  return (
    <>
     {modalShow &&
      <Confirm
        show={modalShow}
        onHide={() => setModalShow(false)}
        deletepayment={deletepayment}
        title="Confirm delete?"
        message="You are going to delete the record. Are you sure?"
        table="payment"
      />}
      {modalShowpayment &&
        <PaymentEdit
          show={modalShowpayment}
          onHide={() => setModalShowpayment(false)}
          parentid={parent.id}
          payment={payment}
          table="lead"
          submitpayments={submitpayments}
        />
        }

      {body ?



        <DatatableWrapper body={body} headers={header} paginationOptionsProps={{
          initialState: {
            rowsPerPage: 5
          }
        }}>
          <Row className="mb-4">
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-start align-items-start"
            >


            </Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-start align-items-start"
            >


            </Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            >
            </Col>
          </Row>
          <Table striped className="related-list-table" responsive = "sm">
            <TableHeader />
            <TableBody />
          </Table>
          <Pagination />
        </DatatableWrapper> : ''}
    </>
  )
};

export default PaymentRelatedList