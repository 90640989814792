import React, { useEffect, useState } from "react";
import { Alert, Badge, Button, Image, Modal, NavDropdown } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useNavigate } from "react-router-dom";
import authApi from "../../api/authApi";
import jwt_decode from "jwt-decode";
import { isMobile, MobileView, BrowserView } from 'react-device-detect';
import { MenuItem } from "react-bootstrap-typeahead";
import Notification from "../common/Notification";
import SparkApi from "../../api/SparkApi";
import CustomAlert from '../CustomAlert';

const Header = ({ socket }) => {
  //('socket',socket)
  const [refreshNotification, setRefreshNotification] = useState(new Date());
  const [showBellAlert, setShowBellAlert] = useState(false);
  const [unreadMsgCount, setUnreadMsgCount] = useState(0);
  const [messages, setMessages] = useState(0);
  const [todaymeetinglist, settodaymeetinglist] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [sidebar, setSidebar] = useState(false);
  const [imgBody, setImgBody] = useState();

  const navigate = useNavigate();
  useEffect(() => {
    messageList()
    if (!localStorage.getItem("token")) navigate("/login");
    if (localStorage.getItem("notifications") && JSON.parse(localStorage.getItem("notifications")).length > 0) {
      navigate("/maintanance");
    }

    try {
      setUserInfo(jwt_decode(localStorage.getItem('token')));
      ////(userInfo.username);
    } catch (error) {
      ////(error)
    }

    //("socket =>", socket)
    socket?.on("greetings", (data) => {
      //("greetings mesg received ", data);
      messageList(false);


    })

  }, [socket]);



  useEffect(() => {
    ////(unreadMsgCount)
    //if(unreadMsgCount > 0)
    //setShowBellAlert(true);
  }, [unreadMsgCount]);

  const formatMessage = (mystr) => {

    let oldStr = mystr;
    mystr = mystr.substring(1);
    var arry = mystr.split(" @");
    var returnArr = [];

    for (var a of arry) {
      var obj = {};
      var value = a.substring(a.lastIndexOf("[") + 1, a.lastIndexOf("]"));
      var key = a.substring(a.lastIndexOf("(") + 1, a.lastIndexOf(")"));
      obj[`username`] = value;
      obj[`id`] = key;
      if (key)
        returnArr.push(obj);
    }
    ////(returnArr)
    for (var a of returnArr) {
      ////(`@[${a.username}](${a.id})`)
      oldStr = oldStr.replace(`@[${a.username}](${a.id})`, `<a href="/users/${a.id}">${a.username}</a>`);
    }

    return oldStr;
  };

  const messageList = async(open) => {
    //('call in use effect')
    const allMeetings = await SparkApi.fetchAllMeetings('today');
    //('allMeetings',allMeetings)
    settodaymeetinglist(todaymeetinglist)
    async function init() {
      let tasks = await SparkApi.fetchUnreadMessages();
      console.log("tasks===>", tasks);
            // If tasks are present, show the alert for 3 seconds
            if (todaymeetinglist.length >= 0) {
              setShowAlert(true);
              setTimeout(() => {
                setShowAlert(false);
              }, 10000); // Close the alert after 3 seconds
            }
      
      if (tasks && tasks?.length > 0) {
        tasks.forEach(msg => {
          msg.description = formatMessage(msg.description);
        });

        setUnreadMsgCount(tasks.length);
        setMessages(tasks)
        setShowNotification(open);
        setShowBellAlert(!open);
      } else {
        ////('No data')
        setMessages([])
        setUnreadMsgCount(0);
        setShowNotification(open);
      }
    }
    init();
  };

  const logout = () => {
    authApi.logout();
    navigate("/login");
  };

  const toggleSidebar = () => {
    //  if(sidebar === false){
    //setSidebar(true);
    ////////(sidebar);
    document.querySelector("#sidebar").classList.toggle("hide");
    document.querySelector("#sidebar").classList.toggle("show");
    //document.querySelector("#minisidebar").classList.toggle("active");
    //}

    //  else{
    //setSidebar(false);
    ////////(sidebar);
    // document.querySelector("#sidebar").classList.toggle("hide");
    // document.querySelector("#sidebar").classList.toggle("show");
    //document.querySelector("#minisidebar").classList.toggle("active");
    // }

  };

  const [showNotification, setShowNotification] = useState(false);

  const closeNotification = () => {
    setShowNotification(false)

  };

  const openNotificationArea = () => {
    ////('showNotification ', showNotification)
    //setShowNotification(!showNotification); 

    if (!showNotification)
      messageList(true);
    else
      setShowNotification(false)
    setShowBellAlert(false);
    //setRefreshNotification(new Date())
  }

  return (
    <>
      <Navbar className="header px-2" bg="" expand="lg" variant="" style={{ marginBottom: isMobile ? "1rem" : "0" }}>
        <button
          type="button"
          id="sidebarCollapse"
          className="btn btn-info"
          onClick={toggleSidebar}

        >
          <i className="fas fa-align-left"></i>
        </button>
        <Navbar.Brand href="#home"></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#" className="p-0" style={{ fontSize: ".9rem" }}>
              <img src={localStorage.getItem("myimage")} className="rounded-circle" style={{ height: "30px" }} />
              <Badge style={{ fontSize: ".9rem" }} bg="light" text="dark">{userInfo.username} </Badge>
              <Badge bg="success" style={{ fontSize: ".9rem" }}>{userInfo.userrole} </Badge> </Nav.Link>
            <Nav.Link href="#" className="d-flex p-0" style={{ alignItems: "center" }}><span className="mx-2" style={{ fontSize: ".9rem" }}>Company</span> <Badge style={{ fontSize: ".9rem" }} bg="secondary">{userInfo.companyname} </Badge> </Nav.Link>
          </Nav>
          {/* <CustomAlert show={showAlert} onClose={() => setShowAlert(false)} /> */}
          <Nav className="ml-auto d-flex align-items-center ">

          
            <div class="icon-wrapper">
              <i className="fa-solid fa-bell cic mx-2" onClick={openNotificationArea} style={{ cursor: "pointer", fontSize: "1.5rem" }}></i>
              {showBellAlert && <span class="notify-number">{unreadMsgCount}</span>}
            </div>

            {/* 
          <i className="fa-solid fa-bell  icon-grey" ></i> */}
            {showNotification &&
              <Notification notifications={messages} closeNotification={closeNotification} refreshNotification={refreshNotification} />}
            {localStorage.getItem("token") ? (
              <Button variant="btn btn-primary" onClick={logout} title="Logout">
                <i className="fa-solid fa-right-from-bracket"></i>
              </Button>
            ) : (
              <></>
            )}

          </Nav>
        </Navbar.Collapse>
      </Navbar>

    </>
  );
};

export default Header;
