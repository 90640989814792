import React, { useEffect, useState } from "react"
import { Button, Col, Container, Form, Spinner, Row, Alert } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import SparkApi from "../api/SparkApi";
import { Shimmer } from "react-shimmer";
import Image from 'react-bootstrap/Image'
import jwt_decode from "jwt-decode";
import moment from "moment";

const UserTracking = () => {
  const [body, setBody] = useState();
  const [userInfo, setUserInfo] = useState(
    jwt_decode(localStorage.getItem("token"))
  );
  console.log('userInfo', userInfo)
  const [body2, setBody2] = useState();
  const [selectedFiles, setSelectedFiles] = useState();
  const [selectedFiles2, setSelectedFiles2] = useState();
  const [thumbnail, setThumbnail] = useState(null);

  const location = useLocation();
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [remarks, setRemarks] = useState();
  const [result, setResult] = useState()
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [locationRecord, setLocationRecord] = useState({});
  const [currentrecord, setCurrentrecord] = useState();
  const [spinner, setSpinner] = useState(false);
  const [alert, setAlert] = useState(null);
  const [address, setAddress] = useState()

  const handleVisitUpload = (event) => {
    setBody(URL.createObjectURL(event.target.files[0]));
    setSelectedFiles(event.target.files[0]);
  };

  const handleSiteUpload = async (event) => {

    setBody2(URL.createObjectURL(event.target.files[0]));
    setSelectedFiles2(event.target.files[0]);

  };

  useEffect(() => {

    if (alert) {
      setTimeout(() => {
        // setAlert(null);
      }, 3000)
    }
  }, [alert])
  const [data, setdata] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      try {
        let loginusermanager = await SparkApi.fetchUserById(userInfo.managerid);
        console.log('loginusermanager', loginusermanager);
        setdata(loginusermanager);
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    async function init() {
      let userdata = await SparkApi.getCurrentUserTrackingRec();
      console.log('userdata:', userdata);
      setCurrentrecord(userdata);
      setLocationRecord(userdata);
      if (userdata.logindatetime != null && userdata.logoutdatetime == null) {
        setButtonDisabled(true)
        setRemarks(userdata.remarks)
      } else if (
        userdata.logindatetime &&
        userdata.logoutdatetime &&
        userdata.logindatetime.length &&
        userdata.logoutdatetime.length
      ) {
        setRemarks(userdata.remarks);
        setButtonDisabled(false);
      }

    }
    init();

    if (!window.myMap) {

      window.myMap = myMap;
      const googleMapScript = document.createElement('script');
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDkVza2FtLItgn_kzJ27_A4l2Eyf3ZrOOI&callback=myMap`;
      googleMapScript.async = true;
      window.document.body.appendChild(googleMapScript);
    } else {
      myMap();
    }


  }, []);

  const myMap = async () => {
    var mapProp = {
      center: new window.google.maps.LatLng(latitude ? latitude : 12, longitude ? longitude : 12),
      zoom: 14,
    };

    const locate = window.navigator && window.navigator.geolocation;
    const currentCoords = { lat: 0, lng: 0 };

    if (locate) {
      locate.getCurrentPosition((position) => {
        currentCoords.lat = position.coords.latitude;
        currentCoords.lng = position.coords.longitude;
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
        mapProp = {
          center: new window.google.maps.LatLng(position.coords.latitude, position.coords.longitude),
          zoom: 14,
        }

        var map = new window.google.maps.Map(document.getElementById("googleMap"), mapProp);

        var geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: currentCoords }, function (results, status) {
          let cur_address = results && results.length > 0 ? results[0].formatted_address : '';
          setAddress(cur_address);
          var marker = new window.google.maps.Marker({
            map: map,
            position: { lat: currentCoords.lat, lng: currentCoords.lng },
            title: cur_address,
            content: cur_address,
          });

          var infowindow = new window.google.maps.InfoWindow({
            content: cur_address
          });
          marker.addListener("click", () => {
            infowindow.open({
              anchor: marker,
              map,
            });
          });

        });

      }, (error) => {
      }, { maximumAge: 10000, timeout: 5000, enableHighAccuracy: true }
      )
    }

    setTimeout(() => {
      //////('mapProp => ', mapProp)


    }, 500)

  }

  const emailInfo = {
    to: "",
    editorHtml: "",
    subject: "",
    from: "",
    ownerid: userInfo.id,
    parentid: "",
  };



  const setLatLongJs = async (checkIn) => {
    const locate = window.navigator && window.navigator.geolocation;
    if (locate) {
      console.log('locate', locate)
      console.log('address checkin', address);
      console.log('remark checkin', remarks)
      locate?.getCurrentPosition(async (position) => {
        console.log('call')
        const now = new Date();
        const istOffset = 5.5 * 60 * 60 * 1000; // IST offset in milliseconds
        const istDateTime = new Date(now.getTime() + istOffset);

        const logindatetime = istDateTime;

        if (checkIn) {
        
          let res = await SparkApi.createCheckInRecord({ 'logindatetime': logindatetime, loginlattitude: position.coords.latitude.toString(), loginlongitude: position.coords.longitude.toString(), remarks, location: address });
          console.log('res', res)
          var formData = new FormData();
          formData.append(`files`, selectedFiles2);
          const result = await SparkApi.createFile(res.id, formData);
          console.log('result', result)
          setTimeout(async () => {
            if (res) {
              setButtonDisabled(true);
              setLocationRecord(res);
              setAlert({ message: 'You have been Successfully Checked In.', type: 'success' });

              emailInfo.to = userInfo.email;
              emailInfo.cc = data.email;
              emailInfo.editorHtml = `<div class="container">
                    <h3>User Check-in Notification</h3>
                    <p>User: <strong>${userInfo.username}</strong>,</p>
                    <p>Successfully checked in to ${address}.</p>
                    <div class="location-info">
                        <p><strong>Date:</strong> ${moment(res.logindatetime).format("DD-MM-YYYY")}</p>
                        <p><strong>Time:</strong> ${moment(res.logindatetime).format("HH:mm:ss")}</p>
                    </div>
                    <p><br></p>
                </div>`;
              emailInfo.subject = "User Tracking";
              emailInfo.from = `${userInfo.username} <${userInfo.email}>`;
              console.log('emailInfo', emailInfo);
              emailInfo.parentid = userInfo.id;
              const emailSend = await SparkApi.sendEmailTask(emailInfo);
              console.log('emailSend', emailSend);





            } else {
              setAlert({ message: 'Something Went Wrong.', type: 'danger' });
            }
          }, 500);

        } else {
          //////('false',result);
          console.log('else call', address)
          let res;
          res = await SparkApi.handlCheckOut({ ...locationRecord, 'logoutdatetime': logindatetime, 'logoutlattitude': position.coords.latitude.toString(), 'logoutlongitude': position.coords.longitude.toString(), 'remarks': remarks })
          setTimeout(async () => {
            if (res) {
              setButtonDisabled(false);
              setAlert({ message: 'You have been Successfully Checked Out.', type: 'success' });


              // userInfo.email = 'shivangi.t@ibirdsservices.com'
              // data.email = 'shivangitak9@gmail.com'

              emailInfo.to = userInfo.email;
              emailInfo.cc = data.email;
              emailInfo.editorHtml = `<div class="container">
                    <h3>Check-Out Notification</h3>
                    <p>User: <strong>${userInfo.username}</strong>,</p>
                    <p>Successfully checked in to ${address}.</p>
                    <div class="location-info">
                        <p><strong>Date:</strong> ${moment(res.logindatetime).format("DD-MM-YYYY")}</p>
                        <p><strong>Time:</strong> ${moment(res.logindatetime).format("HH:mm:ss")}</p>
                    </div>
                    <p><br></p>
                </div>`;
              emailInfo.subject = "User Tracking";
              emailInfo.from = `${userInfo.username} <${userInfo.email}>`;
              console.log('emailInfo', emailInfo);
              emailInfo.parentid = userInfo.id;
              const emailSend = await SparkApi.sendEmailTask(emailInfo);
              console.log('emailSend', emailSend);
              emailInfo.to = `${data.email}`;
              const emailSendagain = await SparkApi.sendEmailTask(emailInfo);
              console.log('emailSend', emailSendagain);
            } else {
              setAlert({ message: 'Something Went Wrong.', type: 'danger' });
            }
          }, 500);


        }
      }, (error) => {
        //////('error in location -> ', error) 
      })
    }
  }

  const handleCheckIn = async () => {
    setLocationRecord({});
    setLatLongJs(true);
  }

  const handleCheckOut = async () => {
    setLatLongJs(false);

  }

  const handleremarkChange = (e) => {
    setRemarks(e.target.value);
  }

  const handleAddressChange = (e) => {
    console.log('call', e)
    setAddress(e.target.value);
  }

  return (
    <div className='p-4'>
      <Container >
        <Row className="view-form">
          <Col lg={8}>

            <Row className="">
              {alert && <Alert variant={alert.type} onClose={() => setAlert(false)} style={{ width: '100%', padding: '15px', margin: "0px 0px 5px 0px", fontWeight: 'bold', textAlign: 'center' }}>
                {alert.message}
              </Alert>}
              {spinner && <Shimmer height={500} ></Shimmer>}
              {!spinner && <div id="googleMap" style={{ width: '100%', height: '300px', border: '1px solid black' }}>

              </div>}
            </Row>
            <Row>
              <Form.Group className="" style={{ padding: '0' }} controlId="formAddress">
                <Form.Label className="form-view-label" htmlFor="formAddress">Address</Form.Label>
                <Form.Control
                  type="text"
                  name="address"
                  placeholder="Enter Address"
                  value={address}
                  onChange={handleAddressChange}
                />

              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="" style={{ padding: '0' }} controlId="formBasicFirstName">
                <Form.Label
                  className="form-view-label"
                  htmlFor="formBasicFirstName"
                >
                  Remarks
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="remarks"
                  value={remarks}
                  placeholder="Enter remarks.."
                  onChange={handleremarkChange}
                />
                {/* {errors.name && touched.name ? (
                    <p className="form-error" style={{ color: "red" }}>
                      {errors.name}
                    </p>    
                  ) : null} */}
              </Form.Group>
            </Row>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Upload Location Pic</Form.Label>
              <Form.Control type="file" multiple onChange={handleSiteUpload} />
              <Image variant="top"
                src={body2}
                //className="rounded-circle"
                thumbnail
                style={{ width: "150px" }}></Image>
            </Form.Group>
            <Row className="my-3">
              <Col>
                <Button
                  disabled={buttonDisabled}
                  variant='success'
                  onClick={() => handleCheckIn()}
                >
                  Check-In
                </Button>
              </Col>
              <Col>
                <Button
                  disabled={!buttonDisabled}
                  variant='danger'
                  onClick={() => handleCheckOut()}
                >
                  Check-Out
                </Button>
              </Col>
              <Row>

              </Row>
            </Row>
          </Col>
          <Col lg-4>

          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default UserTracking;