import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ShimmerTable } from "react-shimmer-effects";
import SparkApi from "../api/SparkApi";
import { useLocation } from "react-router-dom";
import Badge from 'react-bootstrap/Badge';
import moment from "moment";
import CurrencyFormatter from 'currency-formatter-react'

import { isMobile, MobileView, BrowserView } from 'react-device-detect';

import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Link } from "react-router-dom";
import InfoPill from "./InfoPill";

const BusinessList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [body, setBody] = useState([]);
  const [businessname, setBusinessName] = useState();
  // const[contact,setContact] = useState(location.state ? location.state : {});
  const [business, setBusiness] = useState();
  const [sumAmount, setSumAmount] = useState();

  useEffect(() => {


    async function init() {
      const businesses = await SparkApi.fetchBusiness();
      
      console.log('businesses', businesses)
      if (businesses) {
        ////console.log("business data =>", businesses);
        const sum = businesses.reduce((bs, o) => bs + parseFloat(o.amount), 0)
        setSumAmount(sum);
        setBody(businesses);
        setBusiness(businesses);
      } else {
        setBody([]);
        setBusiness([]);
      }
    }
    init();
    setBusinessName(body.firstname + " " + body.lastname);
  }, [businessname]);

  const onFilterType = (event) => {
    if (event.target.value === "") {
      setBody(business);
    } else {
      setBody(
        business.filter((data) => {
          if (
            (data.businessestatus || "").toLowerCase() ===
            (event.target.value || "").toLowerCase()
          ) {
            return data;
          }
        })
      );
    }
  };


  const header = [];
  if (!isMobile) {
    header.push(
      {
        title: "Name",
        prop: "name",
        isFilterable: true, isSortable: true,
        cell: (row) => (
          <Link to={"/business/" + row.id} state={row}>
            {row.name}
          </Link>
        ),
      },
      {
        title: "Account", prop: "accountname", isFilterable: true, isSortable: true,

        cell: (row) => (
          <Link to={"/accounts/" + row.accountid}>
            {row.accountname}
          </Link>
        )
      },
      {
        title: "Contact", prop: "contactname", isFilterable: true, isSortable: true,
        cell: (row) => (
          <Link to={"/contacts/" + row.contactid}>
            {row.contactname}
          </Link>
        )
      },
      {
        title: "Amount", prop: "amount", isFilterable: true, isSortable: true, cell: (row) => (
          <>₹  {parseFloat(row.amount)} </>
        )
      },
      { title: "Payment Model", prop: "paymentmodel", isFilterable: true },


      { title: "Lead Source", prop: "leadsource", isFilterable: true },
      {
        title: "Created Date", prop: "createddate", isFilterable: true, isSortable: true, cell: (row) => (
          <span>{moment(row.createddate).format('DD MMM, yyyy')}</span>
        )
      },
      {
        title: "Staff", prop: "ownername", isFilterable: true, isSortable: true, cell: (row) => (
          <Link to={"/users/" + row.ownerid} state={row}>
            {row.ownername}
          </Link>
        ),
      },
    )
  } else {
    //for mobile device

    header.push(
      {
        title: "Info",
        prop: "name",
        isFilterable: true, isSortable: true,
        cell: (row) => (
          <div className="mobilecard" >
            <Link to={"/business/" + row.id} state={row} style={{ width: "100%" }}>
              {row.name}
            </Link>

            <span><>₹  {parseFloat(row.amount)} </></span>
            <Link to={"/users/" + row.ownerid} state={row}>
              <i class="fa-solid fa-user"></i> {row.ownername}
            </Link>
          </div>
        ),
      },
    )
  }

  // Randomize data of the table columns.
  // Note that the fields are all using the `prop` field of the headers.
  const labels = {
    beforeSelect: " ",
  };

  const createBusiness = () => {
    navigate(`/businesses/e`);
  };

  return (
    <Row className="g-0">

      <Col lg={12} className="px-4">
        <DatatableWrapper
          body={body}
          headers={header}
          paginationOptionsProps={{
            initialState: {
              rowsPerPage: 10,
              options: [5, 10, 15, 20],
            },
          }}
        >
          <Row className="mb-4">
            <Col
              xs={12}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Filter />
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={5}
              className="d-flex flex-col justify-content-start align-items-center"
            >
              <PaginationOptions labels={labels} />
              {/* <Form.Group className="mx-3 mt-4" controlId="formBasicStatus">
                <Form.Select
                  aria-label="Enter status"
                  name="businessestatus"
                  onChange={onFilterType}
                >


                  <option value="">--Select Type--</option>

                
               



                </Form.Select>
              </Form.Group> */}
              <BrowserView>
                <InfoPill left="Count" right={body?.length} /></BrowserView>
              <BrowserView>
                <InfoPill left="Amount" right={<CurrencyFormatter
                  value={sumAmount}
                  currency='INR'
                  thousandSeparator={false}

                />} /></BrowserView>
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              {/* <Button
                className="btn-sm"
                variant="outline-primary mx-2"
                onClick={() => createBusiness(true)}
              >
                New Business
              </Button> */}
              {/* <Button className="btn-sm" variant="outline-primary" onClick={()=>location.state ? navigate(`/contacts/${contact.id}`) : navigate('/contacts/')}>Back to Contact</Button> */}
            </Col>
          </Row>
          {body ? (
            <Table striped className="data-table" responsive="sm">
              <TableHeader />

              <TableBody />
            </Table>
          ) : (
            <ShimmerTable row={10} col={8} />
          )}

          <Pagination />
        </DatatableWrapper>
      </Col>
      <Col lg={2}></Col>
    </Row>
  );
};
export default BusinessList;