import React, { useEffect } from 'react'
import SparkApi from '../api/SparkApi';

const EmailTrigger = ({emailinfo}) => {
    console.log('emailinfo',emailinfo)
    useEffect(() => {
        handleSendEmail()
    }, [emailinfo]);
    const handleSendEmail = async()=>{
        const emailSend = await SparkApi.sendEmailTask(emailinfo);
        console.log('email send true ',emailSend)
    }
  return (
    <div>EmailTrigger</div>
  )
}

export default EmailTrigger