import React, { useState, useEffect } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Typeahead } from "react-bootstrap-typeahead";
import { useLocation, useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "react-bootstrap-typeahead/css/Typeahead.css";
import moment from "moment";
import SparkApi from "../api/SparkApi";
import Select from "react-select";
import jwt_decode from "jwt-decode";
   
const TaskEdit = (props) => {
    const [validated, setValidated] = useState(false);
    const [option, setOption] = useState();
    const [location, setLocation] = useState(useLocation());
    const [navigate, setNavigate] = useState(useNavigate());
    const [task, setTask] = useState(props.task ? props.task.row : { parentid: props.parentid });
    const [taskTargetDate, setTaskTargetDate] = useState();
    const [currentTargetDate, setCurrentTargetDate] = useState();
    let userInfo = jwt_decode(localStorage.getItem("token"));
console.log('props.task',props.task)
    useEffect(() => {
        let userInfo = jwt_decode(localStorage.getItem('token'));

        // let tempValue = {};
        // tempValue.value = userInfo.id;
        // tempValue.label = userInfo.username;
        // setOption(tempValue);

        if (props.task) {
            let temp = {};
            temp.value = props.task.row.ownerid;
            temp.label = props.task.row.ownername;
            setOption(temp);
        }

        if (props.parentid !== null && props.task !== null) {
            let current = new Date();
            task.lastmodifieddate = moment(current).format('YYYY-MM-DD');
        }

        setSelectedUser(task.ownername);
    }, []);

    const [show, setShow] = useState(false);
    const [selectedUser, setSelectedUser] = useState('');
    const [optionUsers, setOptionUsers] = useState([]);
    const [ownerList, setOwnerList] = useState([]);
    const [defaultOwner, setDefaultOwner] = useState([]);
    const [defaultTargetDate, setDefaultTargetDate] = useState(new Date());

    useEffect(() => {
        async function init() {
            const result = await SparkApi.fetchUsers();
            if (result) {
                let ar = result.map((item) => {
                    return {
                        value: item.id,
                        label: item.username
                    };
                });
                setOptionUsers(ar);
            } else {
                setOptionUsers([]);
            }
        }
        init();
    }, []);

    const handleUsers = async (event) => {
        let result = await SparkApi.fetchUserById(event.value);
        setOption(event);
        setSelectedUser(event);
        setTask({ ...task, ownerid: event.value, ownername: event.label, owneremail: result.email });
    }

    const handleChange = (e) => {

        setTask({ ...task, [e.target.name]: e.target.value });
    };

    const handleChange1 = (e) => {
        setTask({ ...task, "startdatetime": e.target.value,"enddatetime":  moment(task?.enddatetime).format('YYYY-MM-DDTHH:mm') });
    };

    const handleChange2 = (e) => {
        setTask({ ...task, "enddatetime": e.target.value ,"startdatetime":  moment(task?.startdatetime).format('YYYY-MM-DDTHH:mm') });
    };

    const checkRequiredFields = () => {
        if (
            task.title && task.title.trim() !== '' &&
            task.status && task.status.trim() !== '' &&
            task.priority && task.priority.trim() !== '' &&
            task.ownerid && task.ownerid.trim() !== '' &&
            task.description && task.description.trim() !== '' && 
            task.startdatetime  &&
            task.enddatetime 
        ) {
            return false;
        }

        return true;
    }

    const handleSubmit = async (e) => {
        ////('targated',task.targetdate);
        // if (task.targetdate === undefined) {
        //     ////('if call')
        //     const targetDateInIST = moment(task.targetdate).tz('Asia/Kolkata').format('YYYY-MM-DDTHH:mm');

        //     task.targetdate = targetDateInIST
        // }
        task.ownerid = option.value;
        task.ownername = option.label;
        let userInfo = jwt_decode(localStorage.getItem('token'));
        task.loginuserid = userInfo.id
        // task.targetdate = moment(task.targetdate).toISOString(); // Fixed the issue with formatting the target date
        e.preventDefault();
        if (checkRequiredFields()) {
            setValidated(true);
            return;
        }

        if (task.id) {
            console.log('task',task)
            task.managername = props.ownername
            console.log('task',task)
            let filteruserdata = await SparkApi.fetchUserById(option.value);
            console.log('filteruserdata', filteruserdata)
            task.managerid = filteruserdata.managerid
            console.log('task',task)
            const result = await SparkApi.saveTask(task);
            if (task.type === 'Call' || task.type === 'Meeting') {
                let emailInfo = {};
                emailInfo.to = task.owneremail;

                emailInfo.editorHtml = `<p><strong>Hi,</strong></p>
                <p>We are pleased to inform you that a new meeting schedule task has been created:</p>
                <p><strong>Task Title:</strong>${result.title}</p>
                <p><strong>Task Description:</strong> ${result.description}</p>
                <p><strong>Meeting Date:</strong> ${moment(result.targetdate).format('DD-MM-YYYY')}</p>
                <p>Please make sure to attend the meeting on time.</p>
                <br>
                <p><strong>Thanks and Regards,</strong></p>
                <p>Ideal Insurance</p>`;
                emailInfo.subject = "Meeting Schedule Notification";
                emailInfo.from = `${userInfo.username} <${userInfo.email}>`;
                emailInfo.parentid = userInfo.id;
                let sendemail = SparkApi.sendEmailTask(emailInfo);
                console.log('email send in loop', emailInfo)

            }
            if (result) {
                submitTasks();
            }
        } else {
            console.log('create task', task)
            task.managername = props.managername
            let filteruserdata = await SparkApi.fetchUserById(option.value);
            console.log('filteruserdata', filteruserdata)
            task.managerid = filteruserdata.managerid
            console.log('task',task)
            const result = await SparkApi.createTask(task);
            console.log('result task', result)
            if (result) {
                if (task.type === 'Call' || task.type === 'Meeting') {
                    // props.lead.owneremail = 'shivangi.t@ibirdsservices.com'
                    // props.lead.relationmanagermail = 'shivangitak9@gmail.com'
                    let emailInfo = {};
                    emailInfo.to = task.owneremail;

                    emailInfo.editorHtml = `<p><strong>Hi,</strong></p>
                    <p>We are pleased to inform you that a new meeting schedule task has been created:</p>
                    <p><strong>Task Title:</strong>${result.title}</p>
                    <p><strong>Task Description:</strong> ${result.description}</p>
                    <p><strong>Meeting Date:</strong> ${moment(result.targetdate).format('DD-MM-YYYY')}</p>
                    <p>Please make sure to attend the meeting on time.</p>
                    <br>
                    <p><strong>Thanks and Regards,</strong></p>
                    <p>Ideal Insurance</p>`;
                    emailInfo.subject = "Meeting Schedule Notification";
                    emailInfo.from = `${userInfo.username} <${userInfo.email}>`;
                    emailInfo.parentid = userInfo.id;
                    let sendemail = SparkApi.sendEmailTask(emailInfo);
                    console.log('email send in loop', emailInfo)

                }
                submitTasks();
            }
        }
    };

    const submitTasks = () => {
        props.submitTasks();
    };

  
    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Edit Task
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className="view-form">
                    <Row>
                        <Col lg={12}>
                            <Form className="mt-3" noValidate validated={validated} onSubmit={handleSubmit} controlId="taskEdit">
                                <Row>
                                    <Alert variant="danger" show={show} className="error-alert">
                                        Please Select Assign Staff
                                    </Alert>
                                    <Col>
                                        <Form.Group className="mx-3" controlId="formBasicTitle">
                                            <Form.Label className="form-view-label" htmlFor="formBasicTitle">
                                                Title
                                            </Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="title"
                                                placeholder="Enter title"
                                                value={task.title}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mx-3" controlId="formBasicType">
                                            <Form.Label className="form-view-label" htmlFor="formBasicType">
                                                Type
                                            </Form.Label>
                                            <Form.Select
                                                aria-label="Enter Payment Status"
                                                name="type"
                                                value={task.type}
                                                onChange={handleChange}
                                            >
                                                <option value=''>--Select--</option>
                                                <option value="Call">Call</option>
                                                <option value="RAA">RAA</option>
                                                <option value="Task">Task</option>
                                                <option value="Meeting">Meeting</option>
                                                <option value="Notes">Notes</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mx-3" controlId="formBasicPriority">
                                            <Form.Label className="form-view-label" htmlFor="formBasicPriority">
                                                Priority
                                            </Form.Label>
                                            <Form.Select required aria-label="Enter Priority" name="priority" value={task.priority} onChange={handleChange}>
                                                <option value="">Select Priority</option>
                                                <option value="Low">Low</option>
                                                <option value="Normal">Normal</option>
                                                <option value="High">High</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mx-3" controlId="formBasicStatus">
                                            <Form.Label className="form-view-label" htmlFor="formBasicStatus">
                                                Status
                                            </Form.Label>
                                            <Form.Select required aria-label="Enter status" name="status" value={task.status} onChange={handleChange}>
                                                <option value="">Select Status</option>
                                                <option value="Not Started">Not Started</option>
                                                <option value="In Progress">In Progress</option>
                                                <option value="Completed">Completed</option>
                                                <option value="Waiting on someone else">Waiting on someone else</option>
                                                <option value="Deferred">Deferred</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>

                                <Col>
                                        <Form.Group className="mx-3" controlId="formBasicTargetdate">
                                            <Form.Label className="form-view-label" htmlFor="formBasicTargetdate">
                                                Start Date
                                            </Form.Label>
                                            <Form.Control
                                                required
                                                type="datetime-local"
                                                name="startdatetime"
                                                placeholder="Enter targetdate"
                                                value={task.startdatetime ? moment(task.startdatetime).format('YYYY-MM-DDTHH:mm') : null}
                                                onChange={handleChange1}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mx-3" controlId="formBasicTargetdate">
                                            <Form.Label className="form-view-label" htmlFor="formBasicTargetdate">
                                                End Date
                                            </Form.Label>
                                            <Form.Control
                                                required
                                                type="datetime-local"
                                                name="enddatetime"
                                                value={task.enddatetime ? moment(task.enddatetime).format('YYYY-MM-DDTHH:mm') : null}
                                                onChange={handleChange2}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                   
                                    <Col>
                                        <Form.Group className="mx-3" controlId="formBasicOwner">
                                            <Form.Label className="form-view-label" htmlFor="formBasicOwner">
                                                Assign Staff
                                            </Form.Label>
                                            <Select
                                                required
                                                name="ownerid"
                                                value={option}
                                                className="custom-select username"
                                                onChange={handleUsers}
                                                options={optionUsers}
                                                getOptionValue={(option) => option.value}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12}>
                                        <Form.Group className="mx-3" controlId="formBasicDescription">
                                            <Form.Label className="form-view-label" htmlFor="formBasicDescription">
                                                Description
                                            </Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                required={validated}
                                                name="description"
                                                placeholder="Enter description"
                                                value={task.description}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={handleSubmit}>Save</Button>
                <Button onClick={props.onHide} variant="light">Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default TaskEdit;
