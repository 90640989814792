import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table, Form } from "react-bootstrap";
import { useNavigate } from 'react-router-dom'
import SparkApi from "../api/SparkApi";
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader
} from 'react-bs-datatable';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom";
import ReportBuilder from "./ReportBuilder";
import Confirm from "./Confirm";

const TaskReports = () => {

    let navigate = useNavigate();
    const [body, setBody] = useState([
        { "reportname": "Lead Summary Report", "iscustom": true,"apiname" : "leadsummaryreport" },
        { "reportname": "Lead Activity Report", "iscustom": true,"apiname" : "leadactivityreport" },
        { "reportname": "Meeting Report", "iscustom": true,"apiname" : "meetingreport" },
    ]
    );
    const [show, setShow] = useState(false);
    const [showReportBuilder, setshowReportBuilder] = useState(false);
    const [tableNameValue, settableNameValue] = useState()



    const header = [
        {
            title: 'Report Name',
            prop: 'reportname',
            isFilterable: true,
            cell: (row) => (
                <Link to={"/taskreports/" + row.apiname} state={row}>
                    {row.reportname}
                </Link>
            )
        },

        {
            title: 'Custom',
            prop: 'iscustom',
            cell: (row) => (
                <input type="checkbox" disabled checked={row.iscustom} />
            )
        },


    ];


    const labels = {
        beforeSelect: " "
    }


    return (

        <Row className="g-0">

            {showReportBuilder === false ?
                <Col lg={12} className="px-4">

                    {body ?
                        <DatatableWrapper body={body} headers={header} paginationOptionsProps={{
                            initialState: {
                                rowsPerPage: 10,
                                options: [5, 10, 15, 20]
                            }
                        }}>
                            <Row className="mb-4">
                                <Col
                                    xs={12}
                                    lg={4}
                                    className="d-flex flex-col justify-content-end align-items-end"
                                >
                                    <Filter />
                                </Col>
                                <Col
                                    xs={12}
                                    sm={6}
                                    lg={4}
                                    className="d-flex flex-col justify-content-start align-items-start"
                                >
                                    <PaginationOptions labels={labels} />


                                </Col>


                            </Row>
                            <Table striped className="data-table" responsive="sm">
                                <TableHeader />
                                <TableBody />
                            </Table>
                            <Pagination />

                        </DatatableWrapper> : ''}
                </Col> : ''}

          


            {showReportBuilder && (
                <ReportBuilder data={tableNameValue} />
            )}


        </Row>



    )
}

export default TaskReports
