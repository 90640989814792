export const API_BASE_URL = `${window.location.protocol}//${window.location.hostname}/ibs`;
// export const API_BASE_URL = "http://localhost:3004";
export const VIEW_LEAD = "VIEW_LEAD";
export const VIEW_PROPERTY = "VIEW_PROPERTY";
export const EDIT_LEAD = "EDIT_LEAD";
export const DELETE_LEAD = "DELETE_LEAD";
export const VIEW_PRODUCT = "VIEW_PRODUCT";
export const EDIT_PRODUCT = "EDIT_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const MODIFY_ALL = "MODIFY_ALL";
export const VIEW_ALL = "VIEW_ALL";
export const VIEW_ORDER = "VIEW_ORDER";
export const VIEW_USER = "VIEW_USER";
export const VIEW_CONTACT = "VIEW_CONTACT";
export const EDIT_CONTACT = "EDIT_CONTACT";
export const DELETE_CONTACT = "DELETE_CONTACT";
export const VIEW_ACCOUNT = "VIEW_ACCOUNT";
export const EDIT_ACCOUNT = "EDIT_ACCOUNT";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const VIEW_BUSINESS = "VIEW_BUSINESS";
export const EDIT_BUSINESS = "EDIT_BUSINESS";
export const DELETE_BUSINESS = "DELETE_BUSINESS";
export const VIEW_INCIDENT = "VIEW_INCIDENT";

//FILE TYPES
export const PDF = "pdf";
export const DOC = "doc";
export const DOCX = "docx";
export const XLS = "xls";
export const XLSX = "xlsx";
export const CSV = "csv";
export const PNG = "png";
export const JPG = "jpg";
export const JPEG = "jpeg";

// export const LEAD_STATUS_VALUES = [{label : 'Open - Not Contacted', islast : false}, {label : 'Working Contacted', islast : false} , {label : 'Closed - Converted', islast : true}, {label : 'Closed - Not Converted', islast : true}];
export const LEAD_STATUS_VALUES =
    [
        {
            label: "Open - Not Contacted",
            sortorder: 1,
            is_converted: false,
            is_lost: false,
        },
        {
            label: "Working - Contacted",
            sortorder: 2,
            is_converted: false,
            is_lost: false,
        },
        {
            label: "Closed - Converted",
            sortorder: 3,
            is_converted: true,
            is_lost: false,
        },
        {
            label: "Closed - Not Converted",
            sortorder: 4,
            is_converted: false,
            is_lost: true,
        },
    ];
export const Product_CATEGORY_VALUES =
    [
        {
            label: "Software",
            sortorder: 1,
            is_converted: false,
            is_lost: false,
        },
        {
            label: "Hardware",
            sortorder: 2,
            is_converted: false,
            is_lost: false,
        },
        {
            label: "Fashion",
            sortorder: 2,
            is_converted: false,
            is_lost: false,
        },
        {
            label: "Retail",
            sortorder: 2,
            is_converted: false,
            is_lost: false,
        },
        {
            label: "Goods",
            sortorder: 2,
            is_converted: false,
            is_lost: false,
        },
        {
            label: "Furniture",
            sortorder: 2,
            is_converted: false,
            is_lost: false,
        },
        {
            label: "Stones",
            sortorder: 2,
            is_converted: false,
            is_lost: false,
        },
        {
            label: "Policy",
            sortorder: 2,
            is_converted: false,
            is_lost: false,
        },
        {
            label: "Others",
            sortorder: 2,
            is_converted: false,
            is_lost: false,
        },


    ];

export const Product_SUBCATEGORY_VALUES =
    [
        {
            label: "Subscription",
            sortorder: 1,
        },
        {
            label: "Insurance",
            sortorder: 2,
        },
        {
            label: "Implementation",
            sortorder: 2,
        },
        {
            label: "Goods",
            sortorder: 2,
        },
        {
            label: "Material",
            sortorder: 2,
        },
        {
            label: "Fashion",
            sortorder: 2,
        },
        {
            label: "Others",
            sortorder: 2,
        }

    ];

export const TYPE_OF_BUSINESS = [
    { key: "New", value: "New" },
    { key: "Renewal", value: "Renewal" },
    { key: "Extension - Project Policy", value: "Extension - Project Policy" },
    { key: "Endorsement", value: "Endorsement" },
    { key: "RFQ only", value: "RFQ only" },
    { key: "Renewal - RFQ only", value: "Renewal - RFQ only" },
    { key: "Port Case", value: "Port Case" },
    { key: "Cross Sale", value: "Cross Sale" },
    // ... (continue with the rest of your data)
];

export const INSURANCE_COMPANYLIST = [
    { key: "RSGIC", value: "Royal Sundaram General Insurance Co. Ltd." },
    { key: "TATA-AIG", value: "TATA AIG General Insurance Co. Ltd." },
    { key: "Reliance GIC", value: "Reliance General Insurance Co. Ltd." },
    { key: "ITGIC", value: "ICICI Lombard General Insurance Co. Ltd." },
    { key: "ILGIC", value: "IFFCO Tokio General Insurance Co. Ltd." },
    { key: "BAGIC", value: "Bajaj Allianz General Insurance Co. Ltd." },
    { key: "HDFC Ergo", value: "HDFC ERGO General Insurance Co. Ltd." },
    { key: "CholaMs", value: "Cholamandalam MS General Insurance Co. Ltd." },
    { key: "Future Generali", value: "Future Generali India Insurance Co. Ltd." },
    { key: "Universal Sompo", value: "Universal Sompo General Insurance Co. Ltd." },
    { key: "Shriram GIC", value: "Shriram General Insurance Co. Ltd." },
    { key: "B-AXA GIC", value: "Bharti AXA General Insurance Co. Ltd." },
    { key: "Raheja QBE", value: "Raheja QBE General Insurance Co. Ltd." },
    { key: "SBI GIC", value: "SBI General Insurance Co. Ltd." },
    { key: "MHDI", value: "Magma HDI General Insurance Co. Ltd." },
    { key: "Liberty GIC", value: "Liberty General Insurance Co. Ltd." },
    { key: "Kotak Mahindra", value: "Kotak Mahindra General Insurance Co. Ltd." },
    { key: "DHFL GIC", value: "DHFL General Insurance Ltd." },
    { key: "Star Health & Allied", value: "Star Health and Allied Insurance Co. Ltd." },
    { key: "Apollo Munich Health", value: "Apollo Munich Health Insurance Co. Ltd." },
    { key: "Max Bupa Health", value: "Max Bupa Health Insurance Co. Ltd." },
    { key: "Religare Health", value: "Religare Health Insurance Co. Ltd." },
    { key: "Cigna TTK Health", value: "Cigna TTK Health Insurance Co. Ltd." },
    { key: "Aditya Birla Health", value: "Aditya Birla Health Insurance Co. Ltd." },
    { key: "Reliance Health", value: "Reliance Health Insurance Co. Ltd." },
    { key: "New India", value: "The New India Assurance Co. Ltd." },
    { key: "National", value: "National Insurance Co. Ltd." },
    { key: "United India", value: "United India Insurance Co. Ltd." },
    { key: "Oriental", value: "Oriental Insurance Co. Ltd." },
    { key: "ECGC", value: "Export Credit Guarantee Corporation of India (ECGC)" },
    { key: "AIC", value: "Agriculture Insurance Company of India Ltd. (AIC)" },
];

export const UNDER_WRITER_STATUS = [
    { name: "Quote Preparation in Progress", value: "Quote Preparation in Progress" },
    { name: "Quote Sent to Insurance Company", value: "Quote Sent to Insurance Company" },
    { name: "Quote/Reply Received from Insurance Company", value: "Quote/Reply Received from Insurance Company" },
    { name: "Risk Inspection/Query", value: "Risk Inspection/Query" },
    { name: "Quote Sent to RM", value: "Quote Sent to RM" },
    { name: "Data Insufficient", value: "Data Insufficient" }
  ];
  