import { json } from 'react-router-dom';
import * as constants from '../constants/CONSTANT';
import authApi from "./authApi";



const SparkApi = {
  //************************ Leads ***********************************//


  //................... Create Lead ................................
  async createLead(lead) {
    console.log('call lead in inventory', lead)
    const token = localStorage.getItem("token");

    let response = await fetch(constants.API_BASE_URL + "/api/leads",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(lead),

      });
    return await response.json();
  },



  //................... Create Lead ................................
  async createBackup(lead) {
    const token = localStorage.getItem("token");

    let response = await fetch(constants.API_BASE_URL + "/api/backup",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(lead),

      });
    return await response.json();
  },

  //...................  Fetch All Lead ................................
  async fetchLead() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/leads", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;

  },

  //...................  Fetch All Lead ................................
  async fetchBackups() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/backup", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;

  },


  //.............. Fetch Lead By Id .............................

  async fetchLeadById(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/leads/" + id, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();

    return result;
  },

  //...................  Fetch All Business ................................
  async fetchBusiness() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/businesses", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;

  },

  //.............. Fetch Business By Id .............................

  async fetchBusinessById(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/businesses/" + id, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();

    return result;
  },

  //...................  Fetch All Lead ................................

  async fetchUsers() {
    //////('if call')
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/auth/users", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });
    //////('response',response)


    const result = await response.json();
    //////('result',result)
    if (result.length > 0) {
      return result;
    }
    return null;

  },




  async fetchUsersByManagerId(info) {
    console.log('info', info)
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/auth/manager/", {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify(info),
    });
    const result = await response.json();
    console.log('response', response)
    return result;
  },
  //.............. Fetch Lead By Id .............................
  async fetchUserById(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/auth/users/" + id, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();
    //////('response',result)
    return result;
  },

  //fetchMedicalTestItemByOwnerId
  async findLeadByOwnerId(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/leads/ld/" + id, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();
    return result;
  },
  //.............. Update Lead .............................
  async updateLead(lead) {
    //('lead', lead)
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/leads/" + lead.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(lead),
      }
    );
    //(response)
    return await response.json();
  },

  //.............. Update Lead .............................
  async updateBusiness(business) {
    ////('business:', business);
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/businesses/" + business.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(business),
      }
    );
    return await response.json();
  },

  // .......... Delete Lead .............................
  async deleteLead(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/leads/" + id,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        }
      }
    );

    return await response.json();

  },

  

  async deleteReport(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/reports/" + id,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        }
      }
    );

    return await response.json();

  },





  // .......... Delete Lead .............................
  async deleteBusiness(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/businesses/" + id,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        }
      }
    );

    return await response.json();

  },

  //************************ Contacts ***********************************//

  //................... Create Contact ................................
  async createContact(contact) {
    const token = localStorage.getItem("token");

    let response = await fetch(constants.API_BASE_URL + "/api/contacts",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(contact),

      });
    return await response.json();
  },

  async saveContact(contact) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/contacts/" + contact.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(contact),
      }
    );
    return await response.json();
  },

  //................... Fetch All Contacts ................................
  async fetchContacts() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/contacts", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;

  },

  //----------------------------------Releted Contacts-----------------------------------
  async findContactByAccountId(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/accounts/" + id + "/contacts", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();
    return result;
  },


  //************************ Task ***********************************//
  async fetchTasks(pid) {
    const token = localStorage.getItem("token");
    ///"+pid+"/*
    let response = await fetch(constants.API_BASE_URL + "/api/tasks/" + pid + "/*", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    //////(response)
    const result = await response.json();
    //////(result);
    if (result.length > 0) {
      //////(result)
      return result;
    }
    return null;

  },


  async fetchTaksByOwner() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/tasks/relatedtasks", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    //////(response)
    const result = await response.json();
    //////(result);
    if (result.length > 0) {
      //////(result)
      return result;
    }
    return null;

  },
  async fetchTaksByOwnerAll(fromDate,toDate,managerid,reportname) {
    let obj = {"fromdate" : fromDate,"toDate" : toDate,"managerid" : managerid,"reportname" : reportname}
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/tasks/reports/relatedtasks/tasks", {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body : JSON.stringify(obj)

    });
    //////(response)
    const result = await response.json();
    //////(result);
    if (result.length > 0) {
      //////(result)
      return result;
    }
    return null;

  },

  async fetchTaksHistory(reportname) {
    let obj = {"reportname" : reportname}
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/tasks/reports/relatedtasks/tasks", {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body : JSON.stringify(obj)

    });
    //////(response)
    const result = await response.json();
    //////(result);
    if (result.length > 0) {
      //////(result)
      return result;
    }
    return null;

  },



  async fetchTaksByAdminRM() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/tasks/meetingsbyadminrm", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    //////(response)
    const result = await response.json();
    //////(result);
    if (result.length > 0) {
      //////(result)
      return result;
    }
    return null;

  },

  async fetchMessages(pid) {
    const token = localStorage.getItem("token");
    ///"+pid+"/*
    let response = await fetch(constants.API_BASE_URL + "/api/messages/" + pid + "/*", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    //////(response)
    const result = await response.json();
    //////(result);
    if (result.length > 0) {
      //////(result)
      return result;
    }
    return null;

  },

  async fetchUnreadMessages() {
    const token = localStorage.getItem("token");

    let response = await fetch(constants.API_BASE_URL + "/api/messages/unread", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token

      }

    });
    //////(response)
    const result = await response.json();
    ////(result);
    if (result.length > 0) {
      //////(result)
      return result;
    }
    return null;

  },

  async markReadMessage(notificationid) {
    const token = localStorage.getItem("token");
    ///"+pid+"/*
    let response = await fetch(constants.API_BASE_URL + "/api/messages/markread/" + notificationid, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      }
    });
    //////(response)
    const result = await response.json();
    ////(result);
    if (result.length > 0) {
      //////(result)
      return result;
    }
    return null;

  },

  async deletePushNotification(notificationid) {
    const token = localStorage.getItem("token");
    ///"+pid+"/*
    let response = await fetch(constants.API_BASE_URL + "/api/messages/markdelete/" + notificationid, {
      method: 'DELETE',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      }
    });
    //////(response)
    const result = await response.json();
    ////(result);
    if (result.length > 0) {
      //////(result)
      return result;
    }
    return null;

  },

  //************************ Task ***********************************//
  async fetchBusinessByParent(pid) {
    const token = localStorage.getItem("token");
    ///"+pid+"/*
    let response = await fetch(constants.API_BASE_URL + "/api/businesses/" + pid + "/*", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    //////(response)
    const result = await response.json();
    //////(result);
    if (result.length > 0) {
      //////(result)
      return result;
    }
    return null;

  },

  async fetchTasksWithoutParent() {
    const token = localStorage.getItem("token");

    let response = await fetch(constants.API_BASE_URL + "/api/tasks/opentasks", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    //////(response)
    const result = await response.json();
    //////(result);
    if (result.length > 0) {

      return result;
    }
    return null;

  },

  async findAllMeetings() {
    const token = localStorage.getItem("token");

    let response = await fetch(constants.API_BASE_URL + "/api/tasks/opentasks", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    //////(response)
    const result = await response.json();
    //////(result);
    if (result.length > 0) {

      return result;
    }
    return null;

  },

  async fetchAllMeetings(today) {
    const token = localStorage.getItem("token");

    let response = await fetch(constants.API_BASE_URL + "/api/tasks/meetings/today", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    //////(response)
    const result = await response.json();
    //////(result);
    if (result.length > 0) {

      return result;
    }
    return null;

  },

  async createTask(task) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/tasks/",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(task),

      });

    return await response.json();

  },

  async createMessage(message) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/messages/",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(message),

      });

    return await response.json();

  },

  async sendEmailTask(task) {
    console.log('task', task);
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/tasks/sendemail",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(task),

      });
    console.log('response', response)
    return await response.json();

  },

  async saveTask(task) {
    //////(task.id)
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/tasks/" + task.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(task),
      }
    );

    return await response.json();

  },

  async deleteTask(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/tasks/" + id,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        }
      }
    );

    return await response.json();

  },

  async deleteMessage(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/messages/" + id,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        }
      }
    );

    return await response.json();

  },


  // ***********************************Home Page***************************************** /api/common/newleads
  //...................  Fetch All New Leads ................................
  async fetchNewLeads() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/common/newleads", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    return result;
    // if (result.length > 0) {
    //   return result;
    // }
    // return null;
  },
  async fetchallcontacts() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/common/allcontacts", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    return result;
    // if (result.length > 0) {
    //   return result;
    // }
    // return null;
  },

  async fetchTotalBusiness() {
    ////('business call:');
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/common/totalbusiness", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    return result;
    // if (result.length > 0) {
    //   return result;
    // }
    // return null;
  },


  async fetchallActiveusers() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/common/activeusers", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    return result;
    // if (result.length > 0) {
    //   return result;
    // }
    // return null;
  },

  async fetchLeadReports(reportname) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/reports/byname/" + reportname, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();

    return result;
  },

  async runReportByName(reportname) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/reports/run/" + reportname, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();

    return result;
  },



  async getCurrentUserTrackingRec() {
    const token = localStorage.getItem("token");
    //////("getCurrentUserTrackingRec");
    let response = await fetch(
      constants.API_BASE_URL + "/api/usertrackings/track/currentrecord",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );

    if (response.status === 401) {
      //authApi.logout();
    }
    const result = await response.json();
    //////("current record from api  in fetch =>", result);
    if (result) {
      ////////('result in fetch => ', result)
      return result;
    }
    return null;
  },

  async saveStaffMemberEditProfile(userid, selectedFiles, staffMember) {
    //////("Enter staff data", userid);
    //////('selectedFiles',selectedFiles)
    const formData = new FormData();
    formData.append('file', selectedFiles);
    formData.append('staffRecord', staffMember);
    //////([...formData])
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL +
      "/api/auth/" +
      userid + "/profile",
      {
        method: "PUT",
        mode: "cors",
        headers: {

          Authorization: token,
        },
        body: formData,
      }
    );
    //////(response);
    return await response.json();
  },


  async createCheckInRecord(locationRecord) {
    const token = localStorage.getItem("token");
    //////("UserLocationRecord => ", locationRecord);

    let response = await fetch(constants.API_BASE_URL + "/api/usertrackings", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(locationRecord),
    });

    if (response.status === 401) {
      //authApi.logout();
    }
    const result = await response.json();
    //////("result in fetch =>", result);
    if (result) {
      ////////('result in fetch => ', result)
      return result;
    }
    return null;
  },
  async fetchUsertrackingsWithstaffId(staffId) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/usertrackings/staff/" + staffId, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }

    });
    const result = await response.json();
    console.log('result tracking', result)
    if (result.length > 0) {
      return result;
    } else {
      return null;
    }


  },


  async fetchSystemNotifications() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/common/notifications", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }

    });
    const result = await response.json();
    //////('result', result)
    if (result && result.length > 0) {
      return result;
    }
    return null;

  },


  async handlCheckOut(locationRecord) {
    const token = localStorage.getItem("token");
    //////("handlCheckOut => ", locationRecord);
    let response = await fetch(
      constants.API_BASE_URL + "/api/usertrackings/" + locationRecord.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(locationRecord),
      }
    );

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    //////("rsult in checkout => ", result);
    if (result) {
      return result;
    }
    return null;
  },


  async getLoginUserData() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/auth/getuser", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });
    const result = await response.json();
    //////('----->', JSON.stringify(result))

    return result;

  },




  async fetchCompanySetting(settingName) {
    //////('--settingName--', settingName)
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/common/settings/" + settingName, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token

      }

    });

    const result = await response.json();
    //////('result', result);
    //////(response)
    return result;


  },



  async fetchFiles(parentId) {
    //////('--parentid--', parentId)
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/files/" + parentId + "/all", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token

      }

    });

    const result = await response.json();
    //////('result', result);
    //////(response)
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  async updateUser(user) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/auth/updatepassword",
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(user),
      }
    );
    return await response.json();
  },



  async downloadFiles(fileid) {
    //////("save", fileid);
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/files/" + fileid + "/download",
      {
        method: "GET",
        //mode: "cors",

        headers: {
          "Authorization": token
        }
      }
    );
    const fileBody = await response.blob();
    ////('filebody in api ', fileBody)
    return fileBody;

  },

  async downloadBackupFile(filename) {
    //////("save", fileid);
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/backup/download/" + filename,
      {
        method: "GET",
        //mode: "cors",

        headers: {
          "Authorization": token
        }
      }
    );
    const fileBody = await response.blob();
    ////('filebody in api ', fileBody)
    return fileBody;

  },

  async deleteBackupFile(filename) {
    //////("save", fileid);
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/backup/delete/" + filename,
      {
        method: "GET",
        //mode: "cors",

        headers: {
          "Authorization": token
        }
      }
    );
    return await response.json();


  },

  //************************ Accounts ***********************************//

  //.................... Crate Account ...................................
  async createAccount(account) {
    const token = localStorage.getItem("token");

    let response = await fetch(constants.API_BASE_URL + "/api/accounts",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(account),

      });
    return await response.json();

  },

  async saveAccount(account) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/accounts/" + account.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(account),
      }
    );
    return await response.json();

  },

  //.......... Fetch All Accounts ..........................................
  async fetchAccounts() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/accounts", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });

    if (response.status === 401) {
      authApi.logout();
    }

    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;

  },

  //.............. Fetch Account By Id .............................
  async fetchAccountById(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/accounts/" + id, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();

    return result;
  },

  //............. Delete Account ............................
  async deleteAccount(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/accounts/" + id,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        }
      }
    );
    return await response.json();
  },


  //************************ Contacts ***********************************//

  //................... Create Contact ................................







  async createUser(user) {
    const token = localStorage.getItem("token");

    let response = await fetch(constants.API_BASE_URL + "/api/auth/createuser",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(user),

      });
    //////('response',response)
    return await response.json();

  },

  /* Start updated by Pooja Vaishnav 12-07-2023 */
  async createUserMsgAlert(msgList) {
    ////('createUserMsgAlert==>', msgList)
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/messages/usermsg",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(msgList),

      });
    ////('response.json()==>', response.json())
    return await response;
  },
  /* End updated by Pooja Vaishnav 12-07-2023 */


  async saveUser(user) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/auth/" + user.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(user),

      }
    );
    //////('response',response)

    return await response.json();

  },



  //.............. Fetch Contact By Id .............................
  async fetchContact(id) {
    ////(id);
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/contacts/" + id, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();

    return result;
  },

  //.............. Fetch Contact By Recordtype Id .............................
  async fetchContactByRecordtype(rtype) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/contacts/t/" + rtype, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();

    return result;
  },

  //.............. Fetch Contact By Recordtype Id .............................
  async fetchContactByPhone(ph) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/contacts/p/" + ph, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();

    return result;
  },

  // .......... Delete Contact .............................
  async deleteContact(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/contacts/" + id,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        }
      }
    );

    return await response.json();

  },




  //************************ Products ***********************************//


  // .................. create product ...................................
  async createProduct(product) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/products",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(product),

      });
    return await response.json();
  },

  async saveLead(lead) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/leads/" + lead.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(lead),
      }
    );
    return await response.json();
  },

  //.................. save Product ............................/
  async saveProduct(product) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/products/" + product.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(product),
      }
    );
    return await response.json();

  },

  //................... Fetch All Products ..............................//
  async fetchProducts() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/products", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  //.............. Fetch Product By Id .............................
  async fetchProduct(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/products/" + id, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();

    return result;
  },

  //............. Delete Product .........................
  async deleteProduct(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/products/" + id,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        }
      }
    );
    return await response.json();
  },


  //********************* Price Book *************************************

  //.................... Crate Pricebook ...................................
  async createPricebook(pricebook) {
    const token = localStorage.getItem("token");

    let response = await fetch(constants.API_BASE_URL + "/api/pricebooks",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(pricebook),

      });
    return await response.json();
  },

  async savePricebook(pricebook) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/pricebooks/" + pricebook.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(pricebook),
      }
    );
    return await response.json();

  },

  //.......... Fetch All Pricebooks........................................
  async fetchPricebooks() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/pricebooks", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });

    if (response.status === 401) {
      authApi.logout();
    }

    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;

  },

  //.............. Fetch Pricebook By Id .............................
  async fetchPricebook(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/pricebooks/" + id + "/*", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();
    return result;
  },

  //----------------------------------Releted Pricebooks-----------------------------------
  async fetchPricebookByProductId(id) {
    ////('inside the fetchPricebookByProductId');
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/pricebooks/" + id, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();
    ////('Pricebook:', result);
    return result;
  },

  //.............. Fetch Pricebook By Owner Id .............................
  async fetchPricebookByOwnerId(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/pricebooks/" + id + "/pid", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();
    return result;
  },

  //.............. Fetch Pricebook By active Id .............................
  async fetchPricebookByActiveId(id) {
    ////('fetchPricebookByActiveId');
    const token = localStorage.getItem("token");
    ////('token==>', token);
    let response = await fetch(constants.API_BASE_URL + "/api/pricebooks/" + id + "/active", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();
    ////('result@@@=>', result)
    return result;
    return null;

  },

  //............. Delete Pricebook ............................
  async deletePricebook(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/pricebooks/" + id,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        }
      }
    );
    return await response.json();
  },



  //************* Payment ****************************/

  //.................... Create Payment ...................................
  async createPayment(payments) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/payments",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(payments),

      });
    return await response.json();
  },

  async savePayment(payment) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/payments/" + payment.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(payment),
      }
    );
    return await response.json();

  },
  //.............. Fetch Payment By business id .............................
  async fetchPayments(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/payments/parent/" + id, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token

      }

    });
    const result = await response.json();
    return result;
  },



  //.............. Fetch Payment By  id .............................
  async fetchPaymentById(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/payments/" + id, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token

      }

    });
    const result = await response.json();
    return result;
  },







  async deletePayment(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/payments/" + id,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        }
      }
    );
    return await response.json();
  },


  //****************** File  *******************

  async createFile(pid, formData) {
    //('pid', pid, 'formData', formData)
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/files/" + pid,
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Authorization": token,
        },
        body: formData,
      }
    );

    return await response.json();
  },

  //************************ files ***********************************//

  async saveFiles(file) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/files/" + file.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(file),
      }
    );

    return await response.json();

  },



  async convertLeadToContact(leadId, convertStatus) {
    ////('convertStatus', convertStatus)

    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/leads/convert/" + leadId, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify({ status: convertStatus }),

    });
    //////('===>',response)
    const result = await response.json();
    //////(result)
    if (result) {
      //////(result)
      return result;
    }
    return null;
  },


  async deleteFile(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/files/" + id,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        }
      }
    );
    return await response.json();
  },

  // --------------------------------- Report ----------------------------------------

  async fetchReports() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/reports", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },


  async fetchReportsById(id) {
    ////(id)
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/reports/" + id,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });
    return await response.json();
  },

  //.............. Fetch Total Count of Accounts .............................
  async fetchCountOfAccounts() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/accounts/cnt", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();

    return result;
  },

  //.............. Fetch Total Count of Doctors in Contact......................
  async fetchCountOfDoctors() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/contacts/total/dr", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();

    return result;
  },

  //.............. Fetch Total Count of Patients in Contact......................
  async fetchCountOfPatients() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/contacts/total/pt", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();

    return result;
  },

  // --------------------------------- Commission ----------------------------------------
  async fetchCommissions() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/commissions", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  async createCommissions(commisson) {
    //////('commisson',commisson)
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/commissions", {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify(commisson),
    });
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  async saveCommissions(commission) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/commissions/" + commission[0].id, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify(commission),
    });
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },

  async deleteCommissions(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/commissions/" + id, {
      method: "DELETE",
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },


  //Report Builder 

  async fetchFilterQueryData(query) {
    ////(query)
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/reportbuilders/filter/" + query, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });
    const result = await response.json();
    ////(result)
    if (result.length > 0) {
      return result;
    }
    return null;
  },
  async fetchTableFields(tablename) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/reportbuilders/" + tablename, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },
  async fetchGroupbyQueryData(query) {
    ////('query', query)
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/reportbuilders/groupbydata/" + query, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });
    ////(response)
    const result = await response.json();
    ////(result)

    return result;

  },
  async fetchTables() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/reportbuilders", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },
  async fetchAllReports() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/reports", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },
  async fetchQueryData(query) {
    ////('fetchQueryData ==> ', query)
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/reportbuilders/record/" + query, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });
    const result = await response.json();
    ////(result)
    ////(result)
    if (result.length > 0) {
      return result;
    }
    return null;
  },
  async createReport(reportdata) {
    ////('enter in inventory', reportdata)
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/reports",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(reportdata),

      });
    return await response.json();
  },

  async fetchReportById(id) {

    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/reports/" + id, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      }

    });
    const result = await response.json();
    ////('result : ', result);
    return result;
  },

  async savereport(id, data) {
    ////('data', data)
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/reports/" + id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(data),
      }
    );
    return await response.json();
  },
  // --------------------------------- Report ----------------------------------------

  async fetchReports() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/reports", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },



  //...................  Fetch All Incidents ................................
  async fetchIncidents() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/incidents", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;

  },
  //................... Create Incident ................................
  async createIncident(incident) {
    ////('createIncident');
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/incidents",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(incident),

      });
    return await response.json();
  },
  //.............. Fetch Incident By Id .............................
  async fetchIncidetById(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/incidents/" + id, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();

    return result;
  },

  //.............. Update incident .............................
  async updateIncident(incident) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/incidents/" + incident.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(incident),
      }
    );
    return await response.json();
  },

  // .......... Delete incident .............................
  async deleteIncident(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/incidents/" + id,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        }
      }
    );
    return await response.json();
  },


  //................... Create Dashboard ................................
  async createDashboard(completedata) {
    ////("create Call", completedata);
    const token = localStorage.getItem("token");

    let response = await fetch(constants.API_BASE_URL + "/api/dashboards/", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(completedata),
    });
    return await response.json();
  },

  //...................  Fetch All Dashboard ................................
  async fetchAllDashboard() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/dashboards/", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  //.............. Fetch Dashboard By Id .............................

  async fetchDashboardById(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/dashboards/" + id,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    const result = await response.json();
    return result;
  },

  //.............. Update Dashboard .............................
  async saveDashboard(id, data) {

    ////("data", data);
    ////("id", id);

    ////("saveDashboard Api Call");
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/dashboards/" + id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(data),
      }
    );
    return await response.json();
  },


  async sendWhatsappMessage(reqbody) {
    let whatsapptoken = 'EAFolVNOk1OIBO6RFxiJvCdX8kPLquJ3BeQfWuQ3st7U6S9mDnQZBXi5ZCIYC8m8D4OClROTYBcFO6uikbHY7tt4NQ3coaiN37KPhHkfYSF7X5sFjrtWuBAEvQyqKQ29MTqQqQJ49ieWnRHhnCopMgXphDOEYXcsjXYMHud7EBex8Yt9kAuDZBEFISGZA7jnrNfqqQC2Qu6ZClkM5biXIZD'
    let response = await fetch(`https://graph.facebook.com/v18.0/111988991779677/messages`,
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${whatsapptoken}`,
        },
        body: JSON.stringify(reqbody),

      });
    //('response', response)
    return await response.json();

  },


  // csv upload 
  async bulkuploadcsv(selectedFile) {
    //('file', selectedFile);
    const token = localStorage.getItem("token");

    const formData = new FormData();
    formData.append('csvfile', selectedFile);

    fetch(constants.API_BASE_URL + '/api/auth/uploadcsv', {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: token,
      },
    })
      .then(response => response.json())
      .then(data => {
        //('data', data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  },
  // async authorizations() {
  //   const username = 'idealwapi';
  //   const password = 'I@`oRHb0X~7J';
  //   const credentials = `${username}:${password}`;
  //   const encodedCredentials = btoa(credentials);
  //   const response = await fetch('https://api.myvfirst.com/psms/api/messages/token?action=generate', {
  //     method: 'POST',
  //     mode: 'cors',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': `Basic ${encodedCredentials}`,
  //     },
   
  //   });
  //   console.log('token response', response)
  //   return await response.json();
  // },



}
export default SparkApi