import React, { useState, useEffect } from "react";
import { Alert, Col, Container, Modal, Row, Table } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import SparkApi from "../api/SparkApi";
import { useLocation } from "react-router-dom";
import PubSub from "pubsub-js";
import Select from "react-select";
import CityState from "../constants/CityState.json";
import * as constants from "../constants/CONSTANT";
import jwt_decode from "jwt-decode";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EmailTrigger from "./EmailTrigger";

const LeadEdit = () => {
  const [validated, setValidated] = useState(false);
  const [feedback, setfeedback] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [lostReason, setLostReason] = useState(false);
  const [userInfo, setUserInfo] = useState(
    jwt_decode(localStorage.getItem("token"))
  );
  const [name, setName] = useState("");
  const [state, setState] = useState({});
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [option, setoption] = useState();
  const [rfqoption, setrfqoption] = useState();
  const [show, setShow] = React.useState(false);
  const [lead, setLead] = useState(location.state ? location.state : {});
  const [leadstatus, setleadstatus] = useState("");
  const [selectedoptionLead, setSelectedOptionLead] = useState();
  const [optionleads, setOptionLead] = useState([]);
  const [allusers, setallusers] = useState([]);
  const [riskIns, setRiskIns] = useState(false);
  const [CNCValidate, setCNCValidate] = useState(false);
  const [fieldValidations, setFieldValidations] = useState({});
  const [sendemailtrigger, setsendemailtrigger] = useState(false);

  const initialFieldValues = {
    "Product Quote": `QTN${Math.floor(Math.random() * 100000)}`,
    // "Policy Expiry Date" : "",

    "Product Code": "",
    "Product Name": "",
    "Under Writing Status": "",
    "Risk Inspection": "",
    "Under Writer Manager": {},
    "Remark": "",
    "Policy Expiry Date": new Date(),
    "insuranceData": [{ inscompanyname: "", premiumquote: "" }],

  };
  const [rows, setRows] = useState([initialFieldValues]);
  console.log('rows', rows)

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Update field validations
        const updatedFieldValidations = { ...fieldValidations };
        updatedFieldValidations['Product Code'] = true;
        updatedFieldValidations['Product Name'] = true;
        setFieldValidations(updatedFieldValidations);
        
        // Fetch all users
        await fetchAllUsers();

        // Set validated to true
        setValidated(true);

        // Fetch all leads
        await fetchAllLeads();

        // Decode JWT token to get user info
        let userInfo = jwt_decode(localStorage.getItem("token"));

        // if (lead.id) {
        //   // Fetch lead data by ID

        //   setRows(lead.data);
        // }


        setoption({ value: lead.rfqid, label: lead.rfqname });
        setrfqoption({ value: lead.rfqid, label: lead.rfqname });
      } catch (error) {
        // Handle errors here
        console.error("Error fetching data:", error);
      }
    };

    // Call fetchData function
    fetchData();

  }, []);
  const [filterobject, setfilterobject] = useState();

  useEffect(() => {

    const updatedFieldValidations = { ...fieldValidations };
    updatedFieldValidations['Product Code'] = true
    updatedFieldValidations['Product Name'] = true
    setFieldValidations(updatedFieldValidations)
    fetchAllUsers()
    setValidated(true);
    const fetchData = async () => {
      try {
        await fetchAllLeads();

        let userInfo = jwt_decode(localStorage.getItem("token"));

        if (lead.id) {
          let resultdata = await SparkApi.fetchLeadById(lead.id);

          if (userInfo.userrole === 'UNDERWRITER_MANAGER') {
            const filteredRows = resultdata.data.filter(item => {
              try {
                const underWriterManager = JSON.parse(item['Under Writer Manager']);
                return underWriterManager.id === userInfo.id;
              } catch (error) {
                console.error('Error parsing Under Writer Manager:', error);
                return false;
              }
            });
            setRows(filteredRows);

            const filteredNotEqualRows = resultdata.data.filter(item => {
              try {
                const underWriterManager = JSON.parse(item['Under Writer Manager']);
                return underWriterManager.id !== userInfo.id;
              } catch (error) {
                console.error('Error parsing Under Writer Manager:', error);
                return false;
              }
            });
            setfilterobject(filteredNotEqualRows);
            console.log('filteredNotEqualRows', filteredNotEqualRows);
          } 

          setRows(lead.data)
          setoption({ value: lead.rfqid, label: lead.rfqname });
          setrfqoption({ value: lead.rfqid, label: lead.rfqname });
        } else {
          console.log("No lead id available.");
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

  }, []);


  const [underwriter, setunderwriter] = useState([]);

  const fetchAllLeads = async () => {
    let temp = [];

    const leads = await SparkApi.fetchLead();
    leads.map((value, index, array) => {
      let obj = {};
      obj.label = value.leadname
      obj.value = value.id
      obj.customerid = value.customerid
      temp.push(obj)
    })
    setOptionLead(temp)
  }


  useEffect(() => {
    fetchAllProducts();
    let userInfo = jwt_decode(localStorage.getItem("token"));
    // if (userInfo.userrole === "RELATIONSHIP_MANAGER") {
    //   //setunderwriterdisable(true);
    // }  else {
    //   setrelationshipdisable(false);
    //   setdisabledstatus(false);
    // }
  }, []);

  useEffect(() => {
    let userInfo = jwt_decode(localStorage.getItem("token"));
    if (location?.state) {
      setleadstatus(lead.leadstatus);
    }

    
    async function init() {

      let st = [];
      CityState.map((item) => {
        var obj = {};
        obj.value = item.state;
        obj.label = item.state;
        st.push(obj);
      });
      let finalStates = {};
      st = st.filter(function (currentObject) {
        if (currentObject.value in finalStates) {
          return false;
        } else {
          finalStates[currentObject.value] = true;
          return true;
        }
      });
      setState(st);
    }

    init();
    fetchAllUser()
  }, []);

  const fetchAllUser = async () => {
    if (userInfo.userrole === 'ADMIN_UNDERWRITING') {
      const fetchUser = await SparkApi.fetchUsersByManagerId(userInfo);
      console.log('fetchUser===>', fetchUser)
      setunderwriter(fetchUser)
    } else if (userInfo.userrole === 'RELATIONSHIP_MANAGER') {
      const fetchUser = await SparkApi.fetchUsers();
      console.log('fetchUser===>1', fetchUser)
      let filterdata = fetchUser.filter((value, index, array) => value.userrole === 'ADMIN_UNDERWRITING' || value.userrole === 'UNDERWRITER_MANAGER');
      console.log('filterdata========', filterdata)
      setunderwriter(filterdata)
    } else if (userInfo.userrole === 'UNDERWRITER_MANAGER') {
      const fetchUser = await SparkApi.fetchUsersByManagerId(userInfo);
      console.log('fetchUser===>', fetchUser)
      setunderwriter(fetchUser)
    }

    else {
      const fetchUser = await SparkApi.fetchUsers();
      console.log('fetchUser', fetchUser)
      let filterUsers = fetchUser.filter((value, index, array) => value.userrole === 'UNDERWRITER_MANAGER' || value.userrole === 'ADMIN_UNDERWRITING')
      setunderwriter(filterUsers)
    }
  }

  const fetchAllUsers = async () => {
    if (userInfo.userrole === 'ADMIN_UNDERWRITING') {
      const fetchUser = await SparkApi.fetchUsers();
      console.log('fetchUser===>', fetchUser)
      setallusers(fetchUser)
    } else if (userInfo.userrole === 'UNDERWRITER_MANAGER') {
      const fetchUser = await SparkApi.fetchUsers();
      console.log('fetchUser===>', fetchUser)
      setallusers(fetchUser)
    }

    else if (userInfo.userrole === 'SUPER_ADMIN') {
      const fetchUser = await SparkApi.fetchUsers();
      console.log('fetchUser===>', fetchUser)
      setallusers(fetchUser)
    }

    else if (userInfo.userrole === 'ADMIN_SALES') {
      const fetchUser = await SparkApi.fetchUsersByManagerId(userInfo);
      console.log('fetchUser===>', fetchUser);
      setallusers(fetchUser)
    } else if (userInfo.userrole === 'RELATIONSHIP_MANAGER') {
      const fetchUser = await SparkApi.fetchUsers();
      console.log('fetchUser===>', fetchUser);
      let filterdata = fetchUser.filter((value, index, array) => value.id === userInfo.id)
      setallusers(filterdata)
    }
  }

  const handleState = (e) => {
    let filteredCities = [];
    CityState.forEach(function (obj) {
      if (obj.state === e.value) {
        filteredCities.push({
          label: obj.name,
          value: obj.name,
        });
      }
    });
    setCities(filteredCities);
    setLead({ ...lead, state: e.value });
  };

  const handleSelectListChange = (value, name) => {
    setLead({ ...lead, [name]: value.value });
    setSelectedCity(value.value);
  };
  //Access all the value input values


  console.log('fieldValidations', fieldValidations)

  const handleChange = (e) => {
    const { name, value } = e.target;

    console.log('name', value, name)
    if (name === "leadstatus") {
      setCNCValidate(value === "Closed - Not Converted");
      setRiskIns(value === "Closed - Converted");
    }

    if (name === "typeofbusiness" && value === "Renewal") {
      setLead({ ...lead, [name]: value, renewal_date: new Date() });
    } else {
      setLead({ ...lead, [name]: value });
    }



  };


  const handleRoleChange = (e) => {
    const selectedUserId = e.target.value;
    const selectedUser = allusers.find((user) => user.id === selectedUserId);
    console.log("selected user", selectedUser);
    if (selectedUser) {
      let filterowneremail = allusers.filter(
        (value) => value.id === selectedUser.id
      );
      console.log('else selected user', selectedUser)
      const { id, firstname, lastname, managerid, managername, email, phone } = selectedUser;
      setrfqoption({ value: id, label: firstname + ' ' + lastname });
      setLead({
        ...lead,
        rfqid: id,
        rfqname: firstname + ' ' + lastname,
        relationmanagermail: email,
        relationshipmanagerphone: phone,
        ownerid: managerid,
        ownername: managername,
        owneremail: filterowneremail[0].email,
        ownerphone: filterowneremail[0].phone
      });
    }
  };

  console.log('firstlead', lead)

  const closeConvertedOnLeadHandler = () => {

    if (CNCValidate && lead?.leadremark && lead.lostreason && lead.leadstatus === "Closed - Not Converted") {
      setCNCValidate(false);
      return false;
    }
    if (riskIns && lead.insurancetype && lead.amount && lead.leadstatus === "Closed - Converted") {
      setRiskIns(false);
      return false;
    }
    return true;
  };

  const checkRequredFields = () => {
    if (lead.typeofcustomer === 'Corporate') {
      const requiredFields = [
        "salutation",
        "phone",
        "firstname",
        "lastname",
        "leadstatus",
        "typeofbusiness",
        "leadsource",
        "zipcode",
        "street",
        "email",
        "title",
        "industry",
        "firstmeetingdate",
        "ownerid",
        "company"
      ];
      for (const field of requiredFields) {
        if (!(lead[field]?.length)) {
          console.log('required field', field)
          toast.error("Please fill all required fields!", {
            position: 'top-center',
          });

          return true;
        }
      }
    } else {


      const requiredFields = [
        "salutation",
        "phone",
        "firstname",
        "lastname",
        "leadstatus",
        "typeofbusiness",
        "leadsource",
        "zipcode",
        "street",
        "email",
        "title",
        "industry",
        "firstmeetingdate",
        "ownerid"

      ];

      for (const field of requiredFields) {
        if (!(lead[field]?.length)) {
          console.log('required field', field)
          toast.error("Please fill all required fields!", {
            position: 'top-center',
          });

          return true;
        }
      }
    }
    return false;
  };


  const sendEmails = async () => {
    for (const value of rows) {
      const underWriterManager = value['Under Writer Manager'];

      if (underWriterManager && Object.keys(underWriterManager).length > 0) {

        if (
          value['Under Writing Status'] === 'Risk Inspection/Query' ||
          value['Under Writing Status'] === 'Data Insufficient' ||
          value['Under Writing Status'] === 'Quote Sent to RM'
        ) {
          let parseddata = JSON.parse(value['Under Writer Manager']);
          let result = await SparkApi.fetchUserById(parseddata.managerid);
          let manageremail = result.email;
          let email = parseddata.email;
          console.log('loop email', email);
          // lead.owneremail = 'shivangi.t@ibirdsservices.com';
          // lead.relationmanagermail = 'shivangitak9@gmail.com';

          let emailInfo = {};
          emailInfo.to = lead.owneremail;
          emailInfo.cc = [
            email,
            lead.relationmanagermail,
            manageremail,
          ];
          emailInfo.editorHtml = `<p><strong>Hello,</strong></p>
                  <p>We are writing to inform you that the status of the lead has been updated:</p>
                  <p><strong>Lead Name:</strong> ${lead.firstname} ${lead.lastname}</p>
                  <p><strong>Updated Lead Status:</strong> ${lead.leadstatus}</p>
                  <p><strong>Updated Status:</strong> ${value['Under Writing Status']}</p>
                  <p><strong>Thanks and Regards,</strong></p>
                  <p>Ideal Insurance</p>`;
          emailInfo.subject = 'Lead Status Update Notification';
          emailInfo.from = `${userInfo.username} <${userInfo.email}>`;
          emailInfo.parentid = userInfo.id;

          let sendemail = await SparkApi.sendEmailTask(emailInfo);
          console.log('email send in loop', emailInfo);
        }
        if (
          value['Under Writing Status'] === 'Quote Preparation in Progress' ||
          value['Under Writing Status'] === 'Quote Sent to Insurance Company' ||
          value['Under Writing Status'] === 'Quote/Reply Received from Insurance Company'
        ) {
          let parseddata = JSON.parse(value['Under Writer Manager']);
          let result = await SparkApi.fetchUserById(parseddata.managerid);
          let manageremail = result.email;
          let email = parseddata.email;
          console.log('loop email', email);


          let emailInfo = {};
          emailInfo.to = email;
          emailInfo.cc = [
            manageremail
          ];
          emailInfo.editorHtml = `<p><strong>Hello,</strong></p>
                  <p>We are writing to inform you that the status of the lead has been updated:</p>
                  <p><strong>Lead Name:</strong> ${lead.firstname} ${lead.lastname}</p>
                  <p><strong>Updated Status:</strong> ${value['Under Writing Status']}</p>
                  <p><strong>Thanks and Regards,</strong></p>
                  <p>Ideal Insurance</p>`;
          emailInfo.subject = 'Lead Status Update Notification';
          emailInfo.from = `${userInfo.username} <${userInfo.email}>`;
          emailInfo.parentid = userInfo.id;

          let sendemail = await SparkApi.sendEmailTask(emailInfo);
          console.log('email send in loop', emailInfo);
        }
      }
    }
  };

  // const sendwhatsappMessage = async (phone) => {
  //   let reqbody = {
  //     "messaging_product": "whatsapp",
  //     "to": phone,
  //     "type": "template",
  //     "template": {
  //       "name": "hello_world",
  //       "language": {
  //         "code": "en_US"
  //       }
  //     }
  //   }
  //   let sendWhatspp = await SparkApi.sendWhatsappMessage(reqbody);
  // }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setsendemailtrigger(false)

    if (lead.leadstatus === "RFQ Sent to UW") {
      lead.rfq_date  = new Date() ;

    }
    if (lead.leadstatus === "Quote Submitted to Client") {
      lead.quatationrecdate = new Date();
    }

    const updatedFieldValidations = { ...fieldValidations };
    console.log('rows', rows)
    for (const row of rows) {
      console.log('loop')


      if (row['Under Writing Status'] === 'Risk Inspection/Query') {
        if (!row['Risk Inspection']) {
          const updatedFieldValidations = { ...fieldValidations };
          updatedFieldValidations['Risk Inspection'] = true
          toast.error("Please Fill Risk Inspection Yes or No", {
            position: 'top-center',
          });
          setFieldValidations(updatedFieldValidations)
          console.error('Please Fill Risk Inspection Yes or No');
          return;
        } else {
          updatedFieldValidations['Risk Inspection'] = false
        }
      }
      if (row['Product Code'] === "" || row['Product Name'] === "") {
        updatedFieldValidations['Product Code'] = true
        updatedFieldValidations['Product Name'] = true
        toast.error("Please fill All Required fields", {
          position: 'top-center',
        });
        setFieldValidations(updatedFieldValidations)
        console.error('Please fill All Required fields');
        return;
      } else {
        updatedFieldValidations['Product Code'] = false
        updatedFieldValidations['Product Name'] = false
      }


      if (lead.leadstatus === 'RFQ Sent to UW' && Object.keys(row['Under Writer Manager']).length === 0) {
        const updatedFieldValidation = { ...fieldValidations };

        console.log('call if for leadstatus');
        updatedFieldValidation['Under Writer Manager'] = true
        toast.error("Under Writer Manager is required for RFQ Sent to UW", {
          position: 'top-center',
        });
        setFieldValidations(updatedFieldValidation)
        console.error('Under Writer Manager is required for RFQ Sent to UW');
        return;
      } else {

        // updatedFieldValidation['Under Writer Manager'] = false
      }


      // Check if the Under Writing Status is Quote Sent to RM
      if (row['Under Writing Status'] === 'Data Insufficient') {
        if (!row['Remark']) {
          toast.error("Remark is required for Data Insufficient", {
            position: 'top-center',
          });
          console.error('Remark is required for Data Insufficient');
          return;
        }
      }
      if (row['Under Writing Status'] === 'Quote Sent to RM') {
        // Check if Remark is empty
        if (!row['Remark']) {
          toast.error("Remark is required for Quote Sent to RM", {
            position: 'top-center',
          });
          console.error('Remark is required for Quote Sent to RM');
          return;
        }

        // Check if insuranceData array is empty or contains invalid values
        // if (!row['insuranceData'] || !Array.isArray(row['insuranceData']) || row['insuranceData'].length === 0) {
        //   console.error('insuranceData is required for Quote Sent to RM');
        //   toast.error("Insurance data is required for Quote Sent to RM in product", {
        //     position: 'top-center',
        //   });
        //   return;
        // }

        // // Check if all keys in each object of insuranceData have non-blank values
        // for (const insurance of row['insuranceData']) {
        //   if (Object.values(insurance).some(value => value === '')) {
        //     const updatedFieldValidation = { ...fieldValidations };
        //     updatedFieldValidation['insuranceData'] = true
        //     setFieldValidations(updatedFieldValidation)
        //     console.error('All keys in insuranceData must have non-blank values');
        //     toast.error("All keys in Insurance Data must have non-blank values", {
        //       position: 'top-center',
        //     });
        //     return;
        //   }
        // }
      }
    }



    setfeedback(true);
    if (closeConvertedOnLeadHandler() && (CNCValidate || riskIns)) {
      console.log('call close');
      setValidated(true);
      toast.error("Please fill all required fields!", {
        position: 'top-center',
      });
      return;
    }


    if (checkRequredFields()) {
      console.log('call validated')
      setValidated(true);
      return;
    }

    const userInfo = jwt_decode(localStorage.getItem("token"));


    let result;
  
    if (lead.id) {
      if (rows.length > 0) {

        sendEmails()
      }


 if (userInfo.userrole === 'UNDERWRITER_MANAGER') {
        let updatedRows = [...filterobject, ...rows];
        console.log('updatedRows', updatedRows)
        lead.data = JSON.stringify(updatedRows)
      } else {
        lead.data = JSON.stringify(rows)
      }
      result = await SparkApi.updateLead(lead);
      if (lead.leadstatus === 'RFQ Sent to UW' || lead.leadstatus === 'RFQ Reassigned to Underwriter' || lead.leadstatus === 'Closed - Converted' || lead.leadstatus === 'Closed - Not Converted') {
        rows?.forEach((value, index, array) => {
          const underWriterManager = value['Under Writer Manager'];

          if (underWriterManager && Object.keys(underWriterManager).length > 0) {
            let parseddata = JSON.parse(underWriterManager);
            console.log('parseddata', parseddata);

            let email = parseddata.email;
            let phone = parseddata.phone;
            // sendwhatsappMessage(phone);
            console.log('loop email', email);
            let emailInfo = {};
            emailInfo.to = lead.owneremail;
            emailInfo.cc = [email, lead.relationmanagermail];
            emailInfo.editorHtml = `<p><strong>Hello,</strong></p>
                  <p>We are writing to inform you that the status of the lead has been updated:</p>
                  <p><strong>Lead Name:</strong> ${lead.firstname} ${lead.lastname}</p>
                  <p><strong>Updated Lead Status:</strong> ${lead.leadstatus}</p>
                  <p><strong>Updated Underwriter Status:</strong> ${value['Under Writing Status']}</p>

                  <p><strong>Thanks and Regards,</strong></p>
                  <p>Ideal Insurance</p>`;
            emailInfo.subject = "Lead Status Update Notification";
            emailInfo.from = `${userInfo.username} <${userInfo.email}>`;
            emailInfo.parentid = userInfo.id;

            let sendemail = SparkApi.sendEmailTask(emailInfo);
            console.log('email send in loop', emailInfo);
          }
        });

        // sendwhatsappMessage(lead.ownerphone)
        // sendwhatsappMessage(lead.relationshipmanagerphone)
      }


      if (lead.leadstatus === 'RFQ in Progress' || lead.leadstatus === 'Proposal on Hold' || lead.leadstatus === 'Details Awaited' || lead.leadstatus === 'Work in Progress' || lead.leadstatus === 'Open - Not Contacted' || lead.leadstatus === 'Revert Pending from Client' || lead.leadstatus === 'Quote Submitted to Client' || lead.leadstatus === 'Quote Received') {
        // lead.owneremail = 'shivangi.t@ibirdsservices.com'
        // lead.relationmanagermail = 'shivangitak1@outlook.com'
        let emailInfo = {};
        emailInfo.to = lead.owneremail;
        emailInfo.cc = lead.relationmanagermail
        emailInfo.editorHtml = ` <p><strong>Hello,</strong></p>
        <p>We are writing to inform you that the status of the lead has been updated:</p>
        <p><strong>Lead Name:</strong> ${lead.firstname} ${lead.lastname}</p>
        <p><strong>Updated Status:</strong> ${lead.leadstatus}</p>
        <p><strong>Thanks and Regards </strong></p>
        <p>Ideal Insurance</p>`;
        emailInfo.subject = "Lead Status Update Notification";
        emailInfo.from = `${userInfo.username} <${userInfo.email}>`;
        emailInfo.parentid = userInfo.id;
        let sendemail = SparkApi.sendEmailTask(emailInfo);
        console.log('email send create', emailInfo)
      }
      console.log("result ===>", result);
      if (result.success) {
        PubSub.publish("RECORD_SAVED_TOAST", {
          title: "Record Saved",
          message: "Record saved successfully",
        });
        navigate(`/leads/${lead.id}`, { state: lead });
      } else {
        PubSub.publish("RECORD_ERROR_TOAST", {
          title: "Error",
          message: result.errors[0],
        });
      }
    } else {

      if (!lead.customerid) {
        const randomNumber = Math.floor(10000 + Math.random() * 90000);
        console.log('randomNumber', randomNumber);
        lead.customerid = `CUST${randomNumber}`;
      }

      if (lead.relationshipmanagerphone && lead.ownerphone) {
        let reqbody = {
          "messaging_product": "whatsapp",
          "to": lead.relationshipmanagerphone,
          "type": "template",
          "template": {
            "name": "hello_world",
            "language": {
              "code": "en_US"
            }
          }
        }
        // let sendWhatspp = await SparkApi.sendWhatsappMessage(reqbody);
        let reqbody2 = {
          "messaging_product": "whatsapp",
          "to": lead.ownerphone,
          "type": "template",
          "template": {
            "name": "hello_world",
            "language": {
              "code": "en_US"
            }
          }
        }
        // let sendWhatspp2 = await SparkApi.sendWhatsappMessage(reqbody2);

      }

      if (lead.owneremail) {
        // lead.owneremail = 'shivangi.t@ibirdsservices.com'
        // lead.relationmanagermail = 'shivangitak9@gmail.com'
        let emailInfo = {};
        emailInfo.to = lead.owneremail;
        emailInfo.cc = lead.relationmanagermail
        emailInfo.editorHtml = `<p><strong>Hi,</strong></p>
        <p>Lead: We are excited to inform you that a new lead has been created:</p>
        <p>Lead Name: ${lead.firstname} ${lead.lastname}</p>
        <p><strong>Thanks and Regards</strong></p>
        <p>Ideal Insurance</p>`;
        emailInfo.subject = "New Lead Created";
        emailInfo.from = `${userInfo.username} <${userInfo.email}>`;
        emailInfo.parentid = userInfo.id;
        let sendemail = SparkApi.sendEmailTask(emailInfo);
        console.log('email send create', emailInfo)
      }
      lead.data = JSON.stringify(rows)
      result = await SparkApi.createLead(lead);
      if (result) {
        PubSub.publish("RECORD_SAVED_TOAST", {
          title: "Record Saved",
          message: "Record saved successfully",
        });
        navigate(`/leads/${result.id}`, { state: result });
      } else {
        window.alert(
          "Failed to create lead. Please check the required fields."
        );
      }
    }
  };

  useEffect(() => {
    if (lead?.leadstatus == "Closed - Converted") {
      setRiskIns(true);
    }
    if (lead?.leadstatus == "Closed - Not Converted") {
      setCNCValidate(true);
    }
  }, [location.state]);

  const handleCancel = () => {
    navigate("/leads/", { state: lead });
  };

  const extractCustomerId = (str) => {
    const match = str.match(/CUST\d{5}/);
    return match ? match[0] : null;
};
  const handleLeadCustomer = async (e) => {
    console.log(e)
    const customerid = extractCustomerId(e.label);
    console.log('customerid',customerid)
    let filterdata = await SparkApi.fetchLeadById(e.value);
    console.log('filterdata', filterdata)
    setSelectedOptionLead(e)
    if (filterdata) {
      lead.salutation = filterdata.salutation
      lead.firstname = filterdata.firstname
      lead.lastname = filterdata.lastname
      lead.company = filterdata.company
      lead.website = filterdata.website
      lead.phone = filterdata.phone
      lead.email = filterdata.email
      lead.title = filterdata.title
      lead.street = filterdata.street
      lead.country = filterdata.country
      lead.city = filterdata.city
      lead.state = filterdata.state
      lead.zipcode = filterdata.zipcode
      lead.customerid = customerid
      lead.comapnygroup = filterdata.comapnygroup
      lead.company = filterdata.company
      lead.typeofcustomer = filterdata.typeofcustomer

      // setLead(filterdata);
    }
    delete filterdata.id
  }



  const [productlist, setproductlist] = useState([]);


  useEffect(() => {
    fetchAllProducts();

  }, []);



  const fetchAllProducts = async () => {
    const products = await SparkApi.fetchProducts();
    setproductlist(products);
  }



  const addRow = () => {
    const randomProductQuote = Math.floor(Math.random() * 100000);
    const newFieldValue = { ...initialFieldValues, "Product Quote": `QTN${randomProductQuote}` };
    const updatedRows = rows ? [...rows, newFieldValue] : [newFieldValue];
    console.log('updatedRows', updatedRows);
    setRows(updatedRows);
  };



  const deleteRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  };


  const handleChangeRows = (value, rowIndex, fieldName) => {
    const updatedRows = [...rows];
    updatedRows[rowIndex][fieldName] = value;
    setRows(updatedRows);
    const updatedFieldValidations = { ...fieldValidations };
    // Check if Under Writing Status is Data Insufficient
    if (fieldName === 'Under Writing Status' && value === 'Data Insufficient' || value === 'Quote Sent to RM') {
      updatedFieldValidations.Remark = true;
    } else {
      updatedFieldValidations.Remark = false;
    }

    if (fieldName === 'Under Writing Status' && value === 'Quote Sent to RM') {
      updatedFieldValidations.insuranceData = true;
    } else {
      updatedFieldValidations.insuranceData = false;
    }
    setFieldValidations(updatedFieldValidations);



    if (fieldName === 'Under Writing Status' && value === 'Data Insufficient') {
      lead.leadstatus = 'Query Raised by Underwriter'
    } else if (fieldName === 'Under Writing Status' && value === 'Quote Sent to RM') {
      lead.leadstatus = 'Quote Received'

    } else if (fieldName === 'Under Writing Status' && value === 'Risk Inspection/Query') {
      lead.leadstatus = 'Query Raised by Underwriter'
    }
  };

  const handleInsuranceChange = (value, rowIndex, fieldIndex, fieldName) => {
    const updatedRows = [...rows];
    updatedRows[rowIndex]["insuranceData"][fieldIndex][fieldName] = value;
    setRows(updatedRows);
  };

  const addInsuranceRow = (rowIndex) => {
    const updatedRows = [...rows];
    updatedRows[rowIndex]["insuranceData"].push({ inscompanyname: "", premiumquote: "" });
    setRows(updatedRows);
  };

  const deleteInsuranceRow = (rowIndex, fieldIndex) => {
    const updatedRows = [...rows];
    updatedRows[rowIndex]["insuranceData"].splice(fieldIndex, 1);
    setRows(updatedRows);
  };

  const renderRows = () => {
    return rows?.map((row, rowIndex) => {
      let minPremiumQuote = Infinity;
      let minInsuranceCompany = "";
      // row.insuranceData.forEach(insurance => {
      //   const premiumquote = parseFloat(insurance.premiumquote);
      //   if (!isNaN(premiumquote) && premiumquote < minPremiumQuote) {
      //     minPremiumQuote = premiumquote;
      //     minInsuranceCompany = insurance.inscompanyname;
      //   }
      // });



      return (
        <div key={rowIndex} style={{ height: '145px' }} className="my-3">
          <ToastContainer />
          <Row lg={12} >
            <Col lg={12}>
              {Object.entries(row)?.map(([fieldName, fieldValue], index) => (
                fieldName !== 'insuranceData' && (
                  index % 6 === 0 && (
                    <Row key={`row-${index}`}>
                      {Object.entries(row)
                        .slice(index, index + 6) // Slice the entries to get the next 6 fields
                        .map(([fieldName, fieldValue], colIndex) => (
                          fieldName !== 'insuranceData' && (
                            <Col key={fieldName} md={2}>
                              <Form.Group controlId={`form-${rowIndex}-${fieldName}`}>
                                <Form.Label>{fieldName}</Form.Label>
                                {fieldType(fieldName) === "select" ? (
                                  <Form.Select
                                    value={fieldValue}
                                    disabled={
                                      (fieldName === 'Product Name' ||
                                      fieldName === 'Product Code' ||
                                      fieldName === 'Under Writer Manager' ||
                                      fieldName === 'Policy Expiry Date') ?
                                      false :
                                      (lead.id && (userInfo.userrole === 'ADMIN_SALES' || userInfo.userrole === 'RELATIONSHIP_MANAGER'))
                                    }
                                    
                                    onChange={(e) => handleChangeRows(e.target.value, rowIndex, fieldName)}
                                    required={fieldValidations[fieldName]}
                                  >
                                    <option>--Select--</option>
                                    {generateSelectOptions(fieldName, rowIndex, productlist, rows)}
                                  </Form.Select>
                                ) : fieldType(fieldName) === "textarea" ? (
                                  <Form.Control
                                    as="textarea"
                                    rows={1}
                                    // /style={{ width: '100%', display: "none" }} // Adjust width as needed
                                    disabled={
                                      (fieldName === 'Product Name' ||
                                      fieldName === 'Product Code' ||
                                      fieldName === 'Under Writer Manager' ||
                                      fieldName === 'Policy Expiry Date') ?
                                      false :
                                      (lead.id && (userInfo.userrole === 'ADMIN_SALES' || userInfo.userrole === 'RELATIONSHIP_MANAGER'))
                                    }                                    value={fieldValue}
                                    onChange={(e) => handleChangeRows(e.target.value, rowIndex, fieldName)}
                                    required={fieldValidations[fieldName]}
                                  />
                                ) : fieldType(fieldName) === "date" ? ( // Corrected this line
                                  <Form.Control
                                    type="date"
                                    value={fieldValue}
                                    disabled={
                                      (fieldName === 'Product Name' ||
                                      fieldName === 'Product Code' ||
                                      fieldName === 'Under Writer Manager' ||
                                      fieldName === 'Policy Expiry Date') ?
                                      false :
                                      (lead.id && (userInfo.userrole === 'ADMIN_SALES' || userInfo.userrole === 'RELATIONSHIP_MANAGER'))
                                    }                                    onChange={(e) => handleChangeRows(e.target.value, rowIndex, fieldName)}
                                    required={fieldValidations[fieldName]}
                                  />
                                ) : (
                                  <Form.Control
                                    type={fieldType(fieldName)}
                                    disabled={
                                      (fieldName === 'Product Name' ||
                                      fieldName === 'Product Code' ||
                                      fieldName === 'Under Writer Manager' ||
                                      fieldName === 'Policy Expiry Date') ?
                                      false :
                                      (lead.id && (userInfo.userrole === 'ADMIN_SALES' || userInfo.userrole === 'RELATIONSHIP_MANAGER'))
                                    }                                    value={fieldName === 'Under Writer Manager' ? fieldValue.username : fieldValue}
                                    onChange={(e) => handleChangeRows(e.target.value, rowIndex, fieldName)}
                                    required={fieldValidations[fieldName]}
                                  />
                                )}
                              </Form.Group>
                            </Col>
                          )
                        ))}

                    </Row>
                  )
                )
              ))}
              <Col md={2} style={{ marginTop: '-36px' }}>
                {userInfo.userrole === 'ADMIN_UNDERWRITING' || userInfo.userrole === 'UNDERWRITER_MANAGER' ? null :
                  <Button variant="danger" className="my-5" onClick={() => deleteRow(rowIndex)}>
                    <i className="fa fa-trash me-2" aria-hidden="true"></i>Delete
                  </Button>}
              </Col>
            </Col>
          </Row>

          <hr style={{ marginTop: '-3%' }}></hr>
        </div>


      );
    });
  };

  const fieldType = (fieldName) => {
    if (fieldName === "Minimum Premium Quoted" || fieldName === "Insurance Company" || fieldName === "Product Quote") {
      return "text";
    } else if (fieldName === "Remark") {
      return "textarea";
    } else if (fieldName === "Policy Expiry Date") {
      return "date"
    } else {
      return "select";
    }

  };

  const generateSelectOptions = (fieldName, rowIndex) => {
    if (fieldName === "Product Code") {
      // Get unique product codes
      const uniqueProductCodes = [...new Set(productlist.map(product => product.productcode))];

      // Map over unique product codes to create options
      return uniqueProductCodes.map((productCode) => (
        <option key={productCode} value={productCode}>
          {productCode}
        </option>
      ));
    } else if (fieldName === "Product Name") {
      // Get the selected product code
      const selectedProductCode = rows[rowIndex]["Product Code"];

      // Filter productlist based on the selected product code
      const filteredProducts = productlist.filter(product => product.productcode === selectedProductCode);

      // Map over filtered products to create options for product names
      return filteredProducts.map((product) => (
        <option key={product.productname} value={product.productname}>
          {product.productname}
        </option>
      ));
    } else if (fieldName === 'Under Writing Status') {
      return constants.UNDER_WRITER_STATUS.map((status) => (
        <option key={status.value} value={status.value}>
          {status.value}
        </option>
      ));
    } else if (fieldName === 'Risk Inspection') {
      return <><option value="Yes">Yes</option>
        <option value="No">No</option></>;
    } else if (fieldName === 'Under Writer Manager') {
      // Assuming `userInfo` contains the current user's information

      return underwriter.map((user, index) => (
        // Checking if the userrole matches the conditions
        (user.userrole === 'UNDERWRITER_MANAGER' || user.userrole === 'ADMIN_UNDERWRITING') && (
          <option key={index} value={JSON.stringify({ id: user.id, username: user.firstname + ' ' + user.lastname, email: user.email, managerid: user.managerid, phone: user.phone })}>
            {user.firstname} {user.lastname}
          </option>
        )
      ));
      return null;
    }
  };

  console.log('rows', rows)

  console.log('lead', lead)
  return (
    <Container className="view-form">
      <Row className="view-form">
        <Col></Col>
        <Col lg={12} className="pb-1 pt-2">
          <Link className="nav-link" to="/leads">
            Home <i className="fa-solid fa-chevron-right"></i>{" "}
            <div style={{ color: "#23468c", display: "inline" }}>Leads</div>
          </Link>
        </Col>
        <Col></Col>
      </Row>

      <Row>
        <Col></Col>
        <Col lg={12}>
          <Form
            className="mt-3"
            // noValidate
            // validated={validated || riskIns}
            onSubmit={handleSubmit}
          >
            <Row className="view-form-header align-items-center">
              <Col lg={7}>
                {name === "" ? <h6>Create Lead</h6> : <h6> Edit Lead</h6>}

                <h5>{name}</h5>
              </Col>
              <Col lg={4} style={{ textAlign: "right", marginLeft: '92px' }}>
                {/* {location?.state?.id ? null : <Button className="btn-sm" onClick={handleSubmit}>
                  Exixting Lead
                </Button>} */}


                <Button className="btn-sm mx-2" onClick={handleSubmit}>
                  Save
                </Button>
                <Button
                  className="btn-sm"
                  variant="danger"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Col>
            </Row>

            <Row className="ibs-edit-form mb-4" lg={12}>
              <span>
                <Alert
                  variant="danger"
                  show={show}
                  className="error-alert my-3"
                >
                  Please provide required fields
                </Alert>
              </span>

              <Col lg={12} className="section-header">
                Relationship Manager Details
              </Col>

              <Col lg={4}>
                <Form.Group>
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicFirstName"
                  >
                    Relationship Manager
                  </Form.Label>


                  <Form.Select
                    required={validated}
                    disabled={userInfo.userrole === 'ADMIN_UNDERWRITING' || userInfo.userrole === 'UNDERWRITER_MANAGER' ? true : false}
                    value={lead.rfqid}

                    onChange={(e) => handleRoleChange(e)}
                  >
                    <option value="">--Select--</option>
                    {allusers?.map((user, ky) => {
                      if (user.userrole === 'RELATIONSHIP_MANAGER' || user.userrole === 'ADMIN_SALES') {
                        return (
                          <option key={ky} value={user?.id}>
                            {user?.firstname} {user?.lastname}
                          </option>
                        );
                      }
                      return null;
                    })}

                  </Form.Select>
                  {feedback && (
                    <Form.Control.Feedback type="invalid">
                      Required
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>


              <Col lg={4}>
                <Form.Group>
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicFirstName"
                  >
                    Manager Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    disabled

                    value={lead.ownername}
                  />

                </Form.Group>
              </Col>



              <Col lg={4}>
                <Form.Group>
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicFees"
                  >
                    Lead Status
                  </Form.Label>
                  <Form.Select
                    required={validated}
                    aria-label="Enter Status"
                    name="leadstatus"
                    disabled={userInfo.userrole === 'ADMIN_UNDERWRITING' || userInfo.userrole === 'UNDERWRITER_MANAGER' ? true : false}
                    onChange={handleChange}
                    value={lead.leadstatus}
                  >
                    <option value="">--Select-Status--</option>
                    <option value="Open - Not Contacted">Open - Not Contacted</option>
                    <option value="Work in Progress">Work in Progress</option>
                    <option value="Details Awaited">Details Awaited</option>
                    <option value="Proposal on Hold">Proposal on Hold</option>
                    <option value="RFQ in Progress">RFQ in Progress</option>
                    <option value="RFQ Sent to UW">RFQ Sent to UW</option>
                    <option value="Query Raised by Underwriter">Query Raised by Underwriter</option>
                    <option value="RFQ Reassigned to Underwriter">RFQ Reassigned to Underwriter</option>
                    <option value="Quote Received">Quote Received</option>
                    <option value="Quote Submitted to Client">Quote Submitted to Client</option>
                    <option value="Revert Pending from Client">Revert Pending from Client</option>
                    <option value="Closed - Converted">Closed - Converted</option>
                    <option value="Closed - Not Converted">Closed - Not Converted</option>
                    <option value="Closed - Partial Converted">Closed - Partial Converted</option>


                  </Form.Select>
                </Form.Group>
              </Col>

              <Col lg={3}>
                <Form.Group>
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicFees"
                  >
                    Type of Business
                  </Form.Label>
                  <Form.Select
                    aria-label="Enter Business"
                    required
                    value={lead.typeofbusiness}
                    disabled={userInfo.userrole === 'ADMIN_UNDERWRITING' || userInfo.userrole === 'UNDERWRITER_MANAGER' ? true : false}
                    name="typeofbusiness"
                    onChange={handleChange}
                  >
                    <option value="">--Select-Business--</option>
                    {constants.TYPE_OF_BUSINESS.map((vl, key) => (
                      <option value={vl.value} key={key}>
                        {vl?.value}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col lg={3}>
                <Form.Group>
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicLeadSource"
                  >
                    Source
                  </Form.Label>

                  <Form.Select
                    aria-label="Enter status"
                    required={validated}
                    value={lead.leadsource}
                    name="leadsource"
                    disabled={userInfo.userrole === 'ADMIN_UNDERWRITING' || userInfo.userrole === 'UNDERWRITER_MANAGER' ? true : false}
                    onChange={handleChange}
                  >
                    <option value="">--Select-Source--</option>
                    <option value="RA Reference">RA Reference</option>
                    <option value="RM Reference">RM Reference</option>
                    <option value="Client Reference">Client Reference</option>
                    <option value="EO Reference">EO Reference</option>
                    <option value="YI Reference">YI Reference</option>
                    <option value="Channel Partner Reference">
                      Channel Partner Reference
                    </option>
                    <option value="BNI Reference">BNI Reference</option>
                    <option value="Direct">Direct</option>
                    <option value="Employee Reference">Employee Reference</option>
                  </Form.Select>
                  {feedback && (
                    <Form.Control.Feedback type="invalid">
                      Required
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>

              <Col lg={3}>
                <Form.Group>
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicFees"
                  >
                    Referal Location
                  </Form.Label>
                  <Form.Select
                    aria-label=""
                    required={validated}
                    value={lead.industry}
                    name="industry"
                    disabled={userInfo.userrole === 'ADMIN_UNDERWRITING' || userInfo.userrole === 'UNDERWRITER_MANAGER' ? true : false}
                    onChange={handleChange}
                  >
                    <option value="">--Select--</option>
                    <option value="BANGALORE">BANGALORE</option>
                    <option value="BHUBANESWAR">BHUBANESWAR</option>
                    <option value="JAMSHEDPUR">JAMSHEDPUR</option>
                    <option value="KOLKATA">KOLKATA</option>
                    <option value="DELHI">DELHI</option>
                    <option value="MUMBAI">MUMBAI</option>
                    <option value="PUNE">PUNE</option>
                    <option value="HYDERABAD">HYDERABAD</option>
                    <option value="DELHI (PV)">DELHI (PV)</option>
                    <option value="GUWAHATI">GUWAHATI</option>
                    <option value="VADODARA">VADODARA</option>
                    <option value="AURANGABAD">AURANGABAD</option>
                    <option value="GANDHIDHAM">GANDHIDHAM</option>
                    <option value="GOA">GOA</option>
                    <option value="GORAKHPUR">GORAKHPUR</option>
                    <option value="JAIPUR">JAIPUR</option>
                    <option value="KANPUR">KANPUR</option>
                    <option value="LUCKNOW">LUCKNOW</option>
                    <option value="LUDHIANA">LUDHIANA</option>
                    <option value="NASIK">NASIK</option>
                    <option value="PATNA">PATNA</option>
                    <option value="PUNE">PUNE</option>
                    <option value="RAIPUR">RAIPUR</option>
                    <option value="SANGLI">SANGLI</option>
                    <option value="SILIGURI">SILIGURI</option>
                    <option value="SURAT">SURAT</option>
                    <option value="VAPI">VAPI</option>
                    <option value="VARANASI">VARANASI</option>
                  </Form.Select>

                  {feedback && (
                    <Form.Control.Feedback type="invalid">
                      Required
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>

              <Col lg={3}>
                <Form.Group>
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicFirstName"
                  >
                    Lead Contact Date
                  </Form.Label>
                  <Form.Control
                    type="date"
                    disabled={userInfo.userrole === 'ADMIN_UNDERWRITING' || userInfo.userrole === 'UNDERWRITER_MANAGER' || lead.id ? true : false}
                    required={validated}
                    name="firstmeetingdate"
                    onChange={handleChange}
                    value={lead.firstmeetingdate}
                  />

                </Form.Group>
              </Col>
              <Col lg={12} className="section-header">
                Leads Details
              </Col>


              {!lead.id && (
                <>
                  <Col lg={3}>
                    <Form.Group>
                      <Form.Label className="form-view-label" htmlFor="formBasicLastName">
                        Existing Lead Customer
                      </Form.Label>
                      <Select
                        placeholder="Select Existing Lead"
                        // isMulti
                        // isSearchable

                        value={selectedoptionLead}
                        onChange={handleLeadCustomer}
                        options={optionleads.map(lead => ({
                          label: `${lead.label} - ${lead.customerid}`,
                          value: lead.value
                        }))}
                      />
                    </Form.Group>
                  </Col>

                </>
              )}

              <Col lg={3}>
                <Form.Group>
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicFirstName"
                  >
                    Type of Customer
                  </Form.Label>
                  <Form.Select
                    name="typeofcustomer"
                    disabled={userInfo.userrole === 'ADMIN_UNDERWRITING' || userInfo.userrole === 'UNDERWRITER_MANAGER' ? true : false}
                    value={lead.typeofcustomer}
                    onChange={handleChange}
                  >
                    <option value="">--Select--</option>
                    <option value="Individual">Individual</option>
                    <option value="Corporate">Corporate</option>

                  </Form.Select>
                  {feedback && (
                    <Form.Control.Feedback type="invalid">
                      Required
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>


              <Col lg={3}>
                <Form.Group>
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicCompany"
                  >
                    Company
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="company"
                    disabled={userInfo.userrole === 'ADMIN_UNDERWRITING' || userInfo.userrole === 'UNDERWRITER_MANAGER' ? true : false}
                    placeholder="Enter Company"
                    required={lead.typeofcustomer === 'Corporate'}
                    value={lead.company}
                    onChange={(e) => handleChange(e)}
                  />
                  {feedback && (
                    <Form.Control.Feedback type="invalid">
                      Required
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>


              <Col lg={3}>
                <Form.Group>
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicCompany"
                  >
                    Company Group
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="comapnygroup"
                    disabled={userInfo.userrole === 'ADMIN_UNDERWRITING' || userInfo.userrole === 'UNDERWRITER_MANAGER' ? true : false}
                    value={lead.comapnygroup}
                    onChange={(e) => handleChange(e)}
                  />

                </Form.Group>
              </Col>

              <Col lg={3}>
                <Form.Group>
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicFirstName"
                  >
                    Salutation
                  </Form.Label>
                  <Form.Select
                    aria-label="Select Salutation"
                    required={validated}
                    disabled={userInfo.userrole === 'ADMIN_UNDERWRITING' || userInfo.userrole === 'UNDERWRITER_MANAGER' ? true : false}
                    name="salutation"
                    value={lead.salutation}
                    onChange={handleChange}
                  >
                    <option value="">--Select--</option>
                    <option value="Mr">Mr.</option>
                    <option value="Mrs">Mrs.</option>
                    <option value="Ms">Ms.</option>
                    <option value="Dr">Dr.</option>
                    <option value="Prof">Prof.</option>
                  </Form.Select>
                  {feedback && (
                    <Form.Control.Feedback type="invalid">
                      Required
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group>
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicFirstName"
                  >
                    First Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="firstname"
                    required={validated}
                    disabled={userInfo.userrole === 'ADMIN_UNDERWRITING' || userInfo.userrole === 'UNDERWRITER_MANAGER' ? true : false}
                    placeholder="Enter First Name"
                    value={lead.firstname}
                    onChange={(e) => handleChange(e)}
                  />
                  {feedback && (
                    <Form.Control.Feedback type="invalid">
                      Required
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>

              <Col lg={3}>
                <Form.Group>
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicLastName"
                  >
                    Last Name
                  </Form.Label>
                  <Form.Control
                    required={validated}
                    type="text"
                    name="lastname"
                    disabled={userInfo.userrole === 'ADMIN_UNDERWRITING' || userInfo.userrole === 'UNDERWRITER_MANAGER' ? true : false}
                    placeholder="Enter LastName"
                    value={lead.lastname}
                    //defaultValue="Ali"
                    onChange={(e) => handleChange(e)}
                  />
                  {feedback && (
                    <Form.Control.Feedback type="invalid">
                      Required
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>

              <Col lg={3}>
                <Form.Group>
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicCompany"
                  >
                    Title
                  </Form.Label>

                  <Form.Select
                    value={lead.title}
                    required={validated}
                    disabled={userInfo.userrole === 'ADMIN_UNDERWRITING' || userInfo.userrole === 'UNDERWRITER_MANAGER' ? true : false}
                    name="title"
                    onChange={handleChange}
                  >
                    <option value="">--Select--</option>
                    <option value="CEO">CEO</option>
                    <option value="Director">Director</option>
                    <option value="Manager">Manager</option>
                    <option value="Owner">Owner</option>
                    <option value="Partner">Partner</option>
                    <option value="Executive">Executive</option>
                    <option value="CFO">CFO</option>
                    <option value="Admin Executive">Admin Executive</option>
                    <option value="HR Executive">HR Executive</option>

                  </Form.Select>
                  {feedback && (
                    <Form.Control.Feedback type="invalid">
                      Required
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>

              <Col lg={4}>
                <Form.Group>
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicFirstName"
                  >
                    Mobile
                  </Form.Label>
                  <Form.Control
                    type="text"
                    required={validated}
                    name="phone"
                    maxLength="10"
                    disabled={userInfo.userrole === 'ADMIN_UNDERWRITING' || userInfo.userrole === 'UNDERWRITER_MANAGER' ? true : false}
                    placeholder="Enter Phone"
                    value={lead.phone}

                    onChange={(e) => handleChange(e)}
                  />
                  {feedback && (
                    <Form.Control.Feedback type="invalid">
                      Required
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>




              <Col lg={4}>
                <Form.Group>
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicCompany"
                  >
                    Email
                  </Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    required={validated}
                    disabled={userInfo.userrole === 'ADMIN_UNDERWRITING' || userInfo.userrole === 'UNDERWRITER_MANAGER' ? true : false}
                    placeholder="Enter Email"
                    value={lead.email}
                    onChange={(e) => handleChange(e)}
                  />
                  {feedback && (
                    <Form.Control.Feedback type="invalid">
                      Required
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>

              {/* <Col lg={3}>
                <Form.Group>
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicCompany"
                  >
                    Website
                  </Form.Label>
                  <Form.Control
                    type="text"
                    disabled={relationshipdisable === true}
                    name="website"
                    placeholder="Enter Website"
                    value={lead.website}
                    onChange={(e) => handleChange(e)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Provide Website
                  </Form.Control.Feedback>
                </Form.Group>
              </Col> */}




              <Col lg={4}>
                <Form.Group>
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicCompany"
                  >
                    Street
                  </Form.Label>
                  <Form.Control
                    type="text"
                    required={validated}
                    disabled={userInfo.userrole === 'ADMIN_UNDERWRITING' || userInfo.userrole === 'UNDERWRITER_MANAGER' ? true : false}
                    name="street"
                    placeholder="Enter Street"
                    value={lead.street}
                    onChange={(e) => handleChange(e)}
                  />
                  {feedback && (
                    <Form.Control.Feedback type="invalid">
                      Required
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group>
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicCompany"
                  >
                    City
                  </Form.Label>
                  <Select
                    options={cities}
                    className="custom-select"
                    value={lead.city ? { label: lead.city, value: lead.city } : null}
                    isDisabled={userInfo.userrole === 'ADMIN_UNDERWRITING' || userInfo.userrole === 'UNDERWRITER_MANAGER' ? true : false}

                    placeholder="Enter City"
                    onChange={(e) => {
                      handleSelectListChange(e, "city");
                    }}
                    name="city"
                    defaultValue={{ label: lead.city, value: lead.city }}
                  />
                  {feedback && (
                    <Form.Control.Feedback type="invalid">
                      Required
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>

              <Col lg={4}>
                <Form.Group>
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicCompany"
                  >
                    State
                  </Form.Label>
                  <Select
                    placeholder="State"
                    className="custom-select"
                    isDisabled={userInfo.userrole === 'ADMIN_UNDERWRITING' || userInfo.userrole === 'UNDERWRITER_MANAGER' ? true : false}
                    defaultValue={{ label: lead.state, value: lead.state }}
                    onChange={handleState}
                    value={lead.state ? { label: lead.state, value: lead.state } : null}
                    options={state}
                  ></Select>
                  {feedback && (
                    <Form.Control.Feedback type="invalid">
                      Required
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>

              <Col lg={4}>
                <Form.Group>
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicCompany"
                  >
                    Pincode
                  </Form.Label>
                  <Form.Control
                    type="text"
                    required={validated}
                    disabled={userInfo.userrole === 'ADMIN_UNDERWRITING' || userInfo.userrole === 'UNDERWRITER_MANAGER' ? true : false}
                    name="zipcode"
                    placeholder="Enter PostalCode"
                    value={lead.zipcode}
                    onChange={(e) => handleChange(e)}
                  />
                  {feedback && (
                    <Form.Control.Feedback type="invalid">
                      Required
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col lg={12} className="section-header">
                Lead Conversion Details
              </Col>

              <Col lg={4}>
                <Form.Group>
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicFees"
                  >
                    Final Premium (₹)
                  </Form.Label>
                  <Form.Control
                    required={riskIns}
                    type="number"
                    disabled={lead.leadstatus !== 'Closed - Converted' || userInfo.userrole === 'ADMIN_UNDERWRITING' || userInfo.userrole === 'UNDERWRITER_MANAGER' ? true : false}

                    name="amount"
                    placeholder="Enter Expected Amount"
                    value={lead.amount}
                    onChange={(e) => handleChange(e)}
                  />

                  {feedback && (
                    <Form.Control.Feedback type="invalid">
                      Required
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>

              <Col lg={4}>
                <Form.Group>
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicFees"
                  >
                    Insurance Company
                  </Form.Label>
                  <Form.Select
                    required={riskIns}
                    value={lead.insurancetype}
                    name="insurancetype"
                    disabled={lead.leadstatus !== 'Closed - Converted' || userInfo.userrole === 'ADMIN_UNDERWRITING' || userInfo.userrole === 'UNDERWRITER_MANAGER' ? true : false}

                    onChange={handleChange}
                  >
                    <option value="">--Select--</option>
                    {constants?.INSURANCE_COMPANYLIST.map((vl, key) => (
                      <option value={vl.key} key={key}>
                        {vl?.key}
                      </option>
                    ))}
                  </Form.Select>
                  {feedback && (
                    <Form.Control.Feedback type="invalid">
                      Required
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>


              <Col lg={6}>
                <Form.Group>
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicFees"
                  >
                    Lead Remark
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={1}
                    required={CNCValidate}
                    name="leadremark"
                    disabled={userInfo.userrole === 'ADMIN_UNDERWRITING' || userInfo.userrole === 'UNDERWRITER_MANAGER' ? true : false}
                    placeholder="Mention Your lead Remarks and Queries"
                    value={lead.leadremark}
                    onChange={handleChange}
                  />
                  {feedback && (
                    <Form.Control.Feedback type="invalid">
                      Required
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>


              <Col lg={6}>
                <Form.Group>
                  <Form.Label className="form-view-label" htmlFor="formBasicFees">
                    Case Lost Reason
                  </Form.Label>
                  <Form.Select
                    name="lostreason"
                    required={CNCValidate}
                    disabled={lead.leadstatus !== 'Closed - Not Converted' || userInfo.userrole === 'ADMIN_UNDERWRITING' || userInfo.userrole === 'UNDERWRITER_MANAGER' ? true : false}
                    value={lead.lostreason}
                    onChange={handleChange}
                  >
                    <option value="">----select---</option>
                    <option value="Quote is not L1">Quote is not L1</option>
                    <option value="Insured has selected the Existing/Other Vendor">Insured has selected the Existing/Other Vendor</option>
                    <option value="The client did not provide the RFQ">The client did not provide the RFQ</option>
                    <option value="Quotation not submitted on time">Quotation not submitted on time</option>
                    <option value="Insured selected other vendor due to Claims Service Issue">Insured selected other vendor due to Claims Service Issue</option>
                  </Form.Select>
                </Form.Group>
              </Col>





              <Row>
                <Col lg={10} className="section-header">
                  Underwriting Journey Information
                </Col>

                <Col lg={2} className="section-header">
                  {userInfo.userrole === 'ADMIN_UNDERWRITING' || userInfo.userrole === 'UNDERWRITER_MANAGER' ? null :
                    <Button onClick={addRow} style={{ float: 'right' }} >Add Product</Button>}
                </Col>


              </Row>

              {renderRows()}

            </Row>
          </Form>
        </Col>
      </Row>

      {sendemailtrigger === true ? <EmailTrigger /> : null}
    </Container>
  );
};
export default LeadEdit;