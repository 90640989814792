import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import SparkApi from '../../api/SparkApi';
// import { cl } from '@fullcalendar/core/internal-common';

const MonthWiseBarChart = () => {

  const [studentCountArr, setStudentCountArr] = useState([]);
  const [staffCountArr, setstaffCountArrr] = useState([]);
  const [branchArr, setBranchArr] = useState([]);
  const [monthName, setMonthName] = useState([]);
  const [monthCount, setMonthCount] = useState([]);

  var month = [];
  useEffect(() => {
    async function init() {
      const fetchLeadReports = await SparkApi.fetchLeadReports('month_wise_lead_report');
      ////("fetchLeadReports=>", fetchLeadReports);
      let queryResult = await SparkApi.fetchQueryData(fetchLeadReports[0].query);
      ////(queryResult)

      let usr = [];
      queryResult.map((item) => {
        var obj = {};
        obj.value = item.createdmonth;
        obj.label = item.totalnew;
        ////(" obj.label >" + obj.label)
        usr.push(obj);
        ////("usr" + JSON.stringify(usr));

        let leadCount = 0;
        let countConverted = 0;
        queryResult.map((data) => {
          if (item.createdmonth === data.createdmonth) {
            leadCount++;
          }
          if (item.createdmonth === data.createdmonth && "Closed - Converted" === data.leadstatus) {
            countConverted++;
          }
        })
        var monObj = {};
        monObj.label = countConverted;
        monObj.value = leadCount;
        month.push(monObj);


      });
      ////("Mon" + JSON.stringify(month));
      ////("usr" + JSON.stringify(usr));
      setMonthCount(month);
      setMonthName(usr);
    }
    init();
  }, [])

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
        text: 'Monthly Lead Report',
      },
    },
  };
  var ch = "";
  const labels = monthName.map((item) => {

    if (ch !== item.value) {
      ch = item.value;
      return item.value;
    }

  });

  //const labels = branchArr;    
  ////("leadCount" + JSON.stringify(monthName));
  ////("motnhhCount" + JSON.stringify(monthCount));
  const data = {

    labels,
    datasets: [
      {
        label: 'Leads',
        data: monthCount.map((item) => {
          return item.value;
        }),
        backgroundColor: 'rgba(255, 215, 0,0.7)',
      },
      {
        label: 'Converted',
        data: monthCount.map((item) => {
          return item.label;
        }),
        backgroundColor: 'rgba(25, 135, 84, 0.7)',
      },
    ],
  };
  return (
    <Bar options={options} data={data} width={100}
      height={50} />
  )
}

export default MonthWiseBarChart
