import React, { useState, useEffect } from 'react';
import { Alert, Button, Container, Form, Modal, ProgressBar } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import jwt_decode from "jwt-decode";
import SparkApi from "../../api/SparkApi";
import PubSub from 'pubsub-js';
import { MultiSelect } from 'react-multi-select-component';

const EmailComposer = (props) => {
    const [value, setValue] = useState('');
    const [email, setEmail] = useState({});
    const [user, setUser] = useState();
    console.log('parentid', props.parentid)
    const [parentid, setParentid] = useState(props.parentid);
    // const [selectedAttachment, setSelectedAttachment] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedAttachments, setSelectedAttachments] = useState([]);
    const [validated, setValidated] = useState(false);

    useEffect(() => {
        setValidated(true)
    }, []);
    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            // text direction

            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme

            [{ 'align': [] }],
            ['link'],
            ['clean'],

        ],
    };

    const formats = [
        'font', 'size',
        'bold', 'italic', 'underline', 'strike',
        'color', 'background',
        'script',
        'header', 'blockquote', 'code-block',
        'indent', 'list',
        'direction', 'align',
        'link', 'image', 'video', 'formula',

    ];

    useEffect(() => {
        fetchAllAttachments()
        let userInfo = jwt_decode(localStorage.getItem('token'));
        let tempValue = { id: userInfo.id, 'username': userInfo.username, email: userInfo.email };
        setUser(tempValue);
        setEmail({ ...email, ['editorHtml']: `<br/><br/><b>Thanks</b><br/>${userInfo.username}` });

    }, []);

    const handleChange = (e) => {
        setEmail({ ...email, [e.target.name]: e.target.value });
    };

    const handleChangeEditor = (html) => {
        setEmail({ ...email, ['editorHtml']: html });
    };

const [alertshow, setalertshow] = useState(false);
    const handleSubmit = async (e) => {
        console.log('email', email)
        if (checkRequredFields()) {
            console.log('call', checkRequredFields())
            console.log('if call')
            setalertshow(true)
            return;
        }
        setLoading(true);

        try {
            console.log('selected attachement', selectedAttachments)
            const resultdownload = await SparkApi.downloadFiles(selectedAttachments.id);
            const base64Content = await blobToBase64(resultdownload);
            email.from = `${user.username}<${user.email}>`;
            email.ownerid = user.id;
            email.parentid = parentid;
            if (attachments) {
                email.attachments = [{
                    path: `data:${selectedAttachments.mimetype || 'application/octet-stream'};base64,${base64Content}`,
                }]
            }
            console.log('email ===>', email);
            const result = await SparkApi.sendEmailTask(email);
            if (result) {
                setLoading(false);
                submitTasks();
                PubSub.publish('RECORD_SAVED_TOAST', { title: 'Email', message: 'Email sent successfully' });
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    function blobToBase64(blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onloadend = () => {
                if (reader.result) {
                    resolve(reader.result.split(',')[1]);
                } else {
                    reject('Error converting Blob to base64');
                }
            };

            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(blob);
        });
    }

    const checkRequredFields = () => {
        if (
            email.subject &&
            email.to.trim() !== ""
        ) {
            return false;
        }
        return true;
    };

    const submitTasks = () => {
        props.submitTasks();
    };

    const [attachments, setattachments] = useState([]);
    const fetchAllAttachments = async () => {
        let files = await SparkApi.fetchFiles(props.parentid);
        console.log('attachment', files)
        if (files) {
            setattachments(files)

        }
    }

    const handleChangeAttachment = (e) => {
        const selectedValue = e.target.value;
        const selectedFile = attachments.find((file) => file.title === selectedValue);
        console.log('selectedFile', selectedFile);
        setSelectedAttachments(selectedFile);
    };

    console.log(selectedAttachments)

    return (

        <Container>


            <Modal

                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Email Composer
                    </Modal.Title>
                </Modal.Header>
                <Alert show={alertshow} variant='danger' className='mx-2 my-2'>Please Enter Required Fields</Alert>
                {!loading ? (<Modal.Body>
                    <Container className="view-form">
                        <Form
                            className="mt-3"
                            onSubmit={handleSubmit}
                        >
                            <Form.Group className="mx-3" controlId="formBasicTitle">
                                <Form.Label className="form-view-label" htmlFor="formBasicTitle">
                                    Subject
                                </Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="subject"
                                    placeholder="Enter subject"
                                    // value={email.title}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group className="mx-3" controlId="formBasicTitle">
                                <Form.Label className="form-view-label" htmlFor="formBasicTitle">
                                    To
                                </Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="to"
                                    placeholder="Comma separated email address"
                                    // value={email.to}
                                    onChange={handleChange}
                                />
                               
                            </Form.Group>
                            <Form.Group className="mx-3" controlId="formBasicTitle">
                                <Form.Label className="form-view-label" htmlFor="formBasicTitle">
                                    Cc
                                </Form.Label>
                                <Form.Control

                                    type="text"
                                    name="cc"
                                    placeholder="Comma separated email address"
                                    // value={email.cc}
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group className="mx-3" controlId="formBasicTitle">
                            <Form.Label className="form-view-label" htmlFor="formBasicTitle">
                                Attachments
                            </Form.Label>
                            <Form.Select name='attachments' onChange={handleChangeAttachment}>
                                <option>--Select--</option>
                                {attachments?.map((value, index) => (
                                    <option key={index} value={value.title}>
                                        {value.title}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                            {/* <Form.Group className="mx-3" controlId="formBasicTitle">
                                <Form.Label className="form-view-label" htmlFor="formBasicTitle">
                                    Attachments
                                </Form.Label>
                                <Form.Select>
                                    <option value="">--Select--</option>
                                    {selectedAttachments.map((value, index) => (
                                        <option key={index} value={value.id}>
                                            {value.title}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group> */}




                            <Form.Group className="mx-3" controlId="formBasicTitle">
                                <Form.Label className="form-view-label" htmlFor="formBasicTitle">
                                    Body
                                </Form.Label>
                                <ReactQuill onChange={handleChangeEditor}
                                    value={email.editorHtml} theme="snow" modules={modules}
                                    formats={formats} />
                            </Form.Group>

                        </Form>
                    </Container>

                </Modal.Body>
                ) : (
                    <div className="sk-cube-grid">
                        <div className="sk-cube sk-cube1"></div>
                        <div className="sk-cube sk-cube2"></div>
                        <div className="sk-cube sk-cube3"></div>
                        <div className="sk-cube sk-cube4"></div>
                        <div className="sk-cube sk-cube5"></div>
                        <div className="sk-cube sk-cube6"></div>
                        <div className="sk-cube sk-cube7"></div>
                        <div className="sk-cube sk-cube8"></div>
                        <div className="sk-cube sk-cube9"></div>
                    </div>


                )}

                <Modal.Footer>

                    <Button variant="success" onClick={handleSubmit}>Save</Button>
                    <Button onClick={props.onHide} variant="light">Close</Button>
                </Modal.Footer>
            </Modal>

        </Container >
    )
}
export default EmailComposer;
