import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
    DatatableWrapper,
    Pagination,
    TableBody,
    TableHeader,
} from 'react-bs-datatable';
import SparkApi from "../api/SparkApi";
import moment from "moment-timezone";
import { Row, Col, Table, Form, Button } from "react-bootstrap";
import Select from "react-select";
import csvDownload from 'json-to-csv-export'
import jwt_decode from "jwt-decode";

const ViewTaskReports = (props) => {
    console.log('props', props);
    const location = useLocation();
    const [state, setState] = useState(location.state ? location.state : {});
    const [reports, setReports] = useState([]);
    const [lead, setlead] = useState([]);
    const [leadActivityReport, setleadActivityReport] = useState([]);

    const [tempdata, settempdata] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [status, setStatus] = useState('');
    const [ownerRole, setOwnerRole] = useState('');
    const [option, setoption] = useState();
    const [optionUsers, setOptionUsers] = useState([]);
    const [userInfo, setUserInfo] = useState(
        jwt_decode(localStorage.getItem("token"))
    );
    const fetchAllUsers = async () => {
        const result2 = await SparkApi.fetchUsers();
        console.log('result2', result2)
        if (result2) {
            let ar = [];

            if (userInfo.userrole === 'ADMIN_SALES') {
                result2?.filter((data) => data.userrole === "ADMIN_SALES" && data.id === userInfo.id).map((item) => {
                    var obj = {};
                    obj.value = item.id;
                    obj.label = item.username;
                    ar.push(obj);
                });

            } else {
                result2?.filter((data) => data.userrole === "ADMIN_SALES").map((item) => {
                    var obj = {};
                    obj.value = item.id;
                    obj.label = item.username;
                    ar.push(obj);
                });
            }
            setOptionUsers(ar);
        } else {
            setOptionUsers([]);
        }

    }

    let tempLabels;
    useEffect(() => {

        fetchAllReportHistory()
    }, []);

    const fetchAllReportHistory = async () => {
        if (state.apiname === 'meetingreport') {
            let result = await SparkApi.fetchTaksHistory(state.apiname)
            try {
                if (result && result.length > 0) {
                    let filterdata = result;
                    console.log('filterdata', filterdata);
                    tempLabels = Object.keys(filterdata[0]);

                    setReports(filterdata);
                    settempdata(filterdata);
                    const headers = Object.keys(filterdata[0])
                        .filter(key => key !== 'managerid' && key !== 'ownerid' && key !== 'Products')
                        .map(key => ({ prop: key, title: key }));
                    console.log('headers', headers)
                    setHeaders(headers);
                } else {
                    setleadActivityReport([]);
                    window.alert('No Data available');
                }
            } catch (error) {
                console.error('Error fetching tasks:', error);

            }
        }

        else if (state.apiname === 'leadsummaryreport') {
            try {
                let result = await SparkApi.fetchTaksHistory(state.apiname);
                console.log('result', result)
                let filterdata = [];
                if (result && result.length > 0) {
                    for (let lead of result) {
                        let entryOrigin = {};
                        for (let productArray of lead.Products) {
                            for (let product of productArray) {
                                // Parse "Under Writer Manager" only if it's a string
                                let underWriterManager = product["Under Writer Manager"];
                                let underWriterManagerUsername = '';

                                // Check if "Under Writer Manager" is a string and parse it
                                if (typeof underWriterManager === 'string') {
                                    try {
                                        let parsedManager = JSON.parse(underWriterManager);
                                        underWriterManagerUsername = parsedManager.username || '';
                                    } catch (error) {
                                        console.error('Error parsing JSON:', error);
                                    }
                                }

                                // Create a new entry for each product along with lead information
                                let entry = {

                                    "Customer Name": lead["Customer Name"],
                                    "Company Name": lead.Company,
                                    "Company Group": lead["Company Group"],
                                    "Relationship Manager Name": lead["Relationship Manager Name"],
                                    "Manager Name": lead["Manager Name"],
                                    "Address": lead.Address,
                                    "Phone Number": lead.Phone,
                                    "Source": lead.Source,
                                    "Type of Business": lead["Type of Business"],
                                    "Lead Contact Date": lead["Lead Contact Date"],
                                    "Lead Creation Date": lead["Lead Creation Date"],
                                    "QRN Number": product["Product Quote"],
                                    "Type of Policy": product["Product Code"] + ' - ' + product["Product Name"],
                                    "Existing Policy Expiry Date": product["Policy Expiry Date"],
                                    "Underwriter Assigned": underWriterManagerUsername,
                                    //"Remark": product.Remark,
                                    //"Policy Expiry Date": product["Policy Expiry Date"],
                                    "RFQ Date": lead["RFQ Date"],
                                    "Quote Sent Date": lead["Quote Sent Date"],
                                    "Lead Remarks": lead["Lead Remarks"],
                                    "Lead Status": lead["Lead Status"],
                                    "Case Lost Reason": lead["Case Lost Reason"],

                                    "Number of Meetings": lead["numberofmeetings"],
                                    "Number of Calls": lead["numberofcalls"],
                                    "RAA done?": lead["RAA Completed"],

                                    "Last Modified Date": lead["Last Modified Date"]
                                };
                                entryOrigin = entry;
                                // Output or process the entry as needed
                                console.log('entry', entry);

                                // Push the entry into the filterdata array

                            }

                        }
                        filterdata.push(entryOrigin);
                    }

                }

                if (result && result.length > 0) {
                    setlead(filterdata); // Set the state with filterdata
                    const headers = Object.keys(filterdata[0])
                        .filter(key => key !== 'managerid' && key !== 'ownerid' && key !== 'Products')
                        .map(key => ({ prop: key, title: key }));

                    console.log('headers', headers);
                    setHeaders(headers);

                    let filterdatanew = filterdata.map((value) => {
                        return {
                            ...value,
                            'Quote Sent Date': value['Quote Sent Date'] ? moment(value['Quote Sent Date']).format('DD-MM-YYYY') : "",
                            'Lead Creation Date': value['Lead Creation Date'] ? moment(value['Lead Creation Date']).format('DD-MM-YYYY') : "",
                            'Existing Policy Expiry Date': value['Existing Policy Expiry Date'] ? moment(value['Existing Policy Expiry Date']).format('DD-MM-YYYY') : "",
                            'RFQ Date': value['RFQ Date'] ? moment(value['RFQ Date']).format('DD-MM-YYYY') : "",
                            'Last Modified Date': moment(value['Last Modified Date']).format('DD-MM-YYYY')
                        };
                    });
                    console.log('filterdatanew', filterdatanew)
                    setReports(filterdatanew);


                } else {
                    setlead([]);
                    window.alert('No Data available');
                }
            } catch (error) {
                console.error('Error fetching tasks:', error);
                // Handle error here, such as displaying an error message to the user
            }
        }
        else {
            //console.log('yrs')
            try {
                let result = await SparkApi.fetchTaksHistory(state.apiname);
                console.log('result activity', result)

                if (result && result.length > 0) {


                    setReports(result)
                    setleadActivityReport(result);
                    const headers = Object.keys(result[0])
                        .filter(key => key !== 'managerid' && key !== 'ownerid' && key !== 'Products')
                        .map(key => ({ prop: key, title: key }));
                    console.log('headers', headers)
                    setHeaders(headers);
                } else {
                    setleadActivityReport([]);
                    window.alert('No Data available');
                }
            } catch (error) {
                console.error('Error fetching tasks:', error);
                // Handle error here, such as displaying an error message to the user
            }
        }

    }
    const fetchAllData = async () => {
        //console.log('sfddsgg')
        if (state.apiname === 'meetingreport') {
            let result = await SparkApi.fetchTaksByOwnerAll(moment(fromDate).format("DD-MM-YYYY"), moment(toDate).format("DD-MM-YYYY"), option?.value, state.apiname)
            try {
                if (result && result.length > 0) {
                    let filterdata = result;
                    //console.log('filterdata', filterdata);
                    tempLabels = Object.keys(filterdata[0]);

                    setReports(filterdata);
                    settempdata(filterdata);
                    const headers = Object.keys(filterdata[0])
                        .filter(key => key !== 'managerid' && key !== 'ownerid' && key !== 'Products')
                        .map(key => ({ prop: key, title: key }));
                    console.log('headers', headers)
                    setHeaders(headers);
                } else {

                    window.alert('No Data available');
                }
            } catch (error) {
                console.error('Error fetching tasks:', error);

            }
        }

        else if (state.apiname === 'leadsummaryreport') {
            try {
                let result = await SparkApi.fetchTaksByOwnerAll(
                    moment(fromDate).format("DD-MM-YYYY"),
                    moment(toDate).format("DD-MM-YYYY"),
                    option?.value,
                    state.apiname
                );
                //console.log('result', result)
                let filterdata = [];
                if (result && result.length > 0) {
                    for (let lead of result) {
                        let entryOrigin;
                        for (let productArray of lead.Products) {
                            for (let product of productArray) {
                                // Parse "Under Writer Manager" only if it's a string
                                let underWriterManager = product["Under Writer Manager"];
                                let underWriterManagerUsername = '';

                                // Check if "Under Writer Manager" is a string and parse it
                                if (typeof underWriterManager === 'string') {
                                    try {
                                        let parsedManager = JSON.parse(underWriterManager);
                                        underWriterManagerUsername = parsedManager.username || '';
                                    } catch (error) {
                                        console.error('Error parsing JSON:', error);
                                    }
                                }

                                // Create a new entry for each product along with lead information
                                let entry = {

                                    "Customer Name": lead["Customer Name"] ? lead["Customer Name"] : "",
                                    "Company Name": lead.Company ? lead.Company : "",
                                    "Company Group": lead["Company Group"],
                                    "Relationship Manager Name": lead["Relationship Manager Name"],
                                    "Manager Name": lead["Manager Name"],
                                    "Address": lead.Address,
                                    "Phone Number": lead.Phone,
                                    "Source": lead.Source,
                                    "Type of Business": lead["Type of Business"],
                                    "Lead Contact Date": lead["Lead Contact Date"],
                                    "Lead Creation Date": lead["Lead Creation Date"],
                                    "QRN Number": product["Product Quote"],
                                    "Type of Policy": product["Product Code"] + ' - ' + product["Product Name"],
                                    "Existing Policy Expiry Date": product["Policy Expiry Date"],
                                    "Underwriter Assigned": underWriterManagerUsername,
                                    //"Remark": product.Remark,
                                    //"Policy Expiry Date": product["Policy Expiry Date"],
                                    "RFQ Date": lead["RFQ Date"],
                                    "Quote Sent Date": lead["Quote Sent Date"],
                                    "Lead Remarks": lead["Lead Remarks"],
                                    "Lead Status": lead["Lead Status"],
                                    "Case Lost Reason": lead["Case Lost Reason"],

                                    "Number of Meetings": lead["numberofmeetings"],
                                    "Number of Calls": lead["numberofcalls"],
                                    "RAA done?": lead["RAA Completed"],

                                    "Last Modified Date": lead["Last Modified Date"]
                                };
                                entryOrigin = entry;
                                // Output or process the entry as needed
                                console.log('entry', entry);

                                // Push the entry into the filterdata array

                            }

                        }
                        filterdata.push(entryOrigin);
                    }
                }

                if (filterdata && filterdata.length > 0) {
                    console.log('filterdata',filterdata)
                    setlead(filterdata); // Set the state with filterdata
                    const headers = Object.keys(filterdata[0])
                        .filter(key => key !== 'managerid' && key !== 'ownerid' && key !== 'Products')
                        .map(key => ({ prop: key, title: key }));

                    //console.log('headers', headers);
                    setHeaders(headers);

                    let filterdatanew = filterdata.map((value) => {
                        return {
                            ...value,
                            'Quote Sent Date': value  && value['Quote Sent Date'] && value['Quote Sent Date']!== null && value['Quote Sent Date'] !== undefined  ? moment(value['Quote Sent Date']).format('DD-MM-YYYY') : "",
                            'Lead Creation Date': value  && value['Lead Creation Date'] && value['Lead Creation Date']!== null && value['Lead Creation Date'] !== undefined ? moment(value['Lead Creation Date']).format('DD-MM-YYYY') : "",
                            'Existing Policy Expiry Date': value  && value['Existing Policy Expiry Date'] && value['Existing Policy Expiry Date']!== null && value['Existing Policy Expiry Date'] !== undefined ? moment(value['Existing Policy Expiry Date']).format('DD-MM-YYYY') : "",
                            'RFQ Date': value  && value['RFQ Date'] && value['RFQ Date']!== null && value['RFQ Date'] !== undefined ? moment(value['RFQ Date']).format('DD-MM-YYYY') : "",
                            'Last Modified Date': value ? moment(value['Last Modified Date']).format('DD-MM-YYYY'):"",
                        };
                    });
                    setReports(filterdatanew);
                } else {
                    setlead([]);
                    window.alert('No Data available');
                }
                
            } catch (error) {
                console.error('Error fetching tasks:', error);
                // Handle error here, such as displaying an error message to the user
            }
        }
        else {
            try {
                let result = await SparkApi.fetchTaksByOwnerAll(
                    moment(fromDate).format("DD-MM-YYYY"),
                    moment(toDate).format("DD-MM-YYYY"),
                    option?.value,
                    state.apiname
                );
                //console.log('result activity', result)

                if (result && result.length > 0) {

                    setReports(result)
                    setleadActivityReport(result);
                    const headers = Object.keys(result[0])
                        .filter(key => key !== 'managerid' && key !== 'ownerid' && key !== 'Products')
                        .map(key => ({ prop: key, title: key }));
                    console.log('headers', headers)
                    setHeaders(headers);
                } else {
                    setleadActivityReport([]);
                    window.alert('No Data available');
                }
            } catch (error) {
                console.error('Error fetching tasks:', error);
                // Handle error here, such as displaying an error message to the user
            }
        }

    };

    const handleUsers = async (event) => {

        setoption(event)
    }

    useEffect(() => {
        fetchAllUsers()

    }, []);

    //console.log('reports',reports)
    const ReportDataCSV = {
        data: reports,
        filename: 'Meeting_Report',
        delimiter: ',',
        headers: tempLabels
    }

    const [expandedRow, setExpandedRow] = useState(null);

    const toggleRowDetails = (rowId) => {
        console.log('rowid', rowId)
        setExpandedRow(expandedRow === rowId ? null : rowId);
    };

    //console.log('leads', lead)

    return (
        <Row className="g-0">
            <Col lg={12} className="px-4 my-3">
                <Row className="mb-4">
                    <Col xs={12} sm={6} lg={3}>
                        <Form.Label htmlFor="fromDate">From Date</Form.Label>
                        <Form.Control
                            id="fromDate"
                            type="date"
                            value={fromDate}
                            onChange={(e) => setFromDate(e.target.value)}
                            placeholder="From Date"
                        />
                    </Col>
                    <Col xs={12} sm={6} lg={3}>
                        <Form.Label htmlFor="toDate">To Date</Form.Label>
                        <Form.Control
                            id="toDate"
                            type="date"
                            value={toDate}
                            onChange={(e) => setToDate(e.target.value)}
                            placeholder="To Date"
                        />
                    </Col>
                    {/* {state.apiname !== 'meetingreport' && (
                        <Col xs={12} sm={6} lg={3}>
                            <Form.Label htmlFor="status">Status</Form.Label>
                            <Form.Select
                                id="status"
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                                placeholder="Status"
                            >
                                <option value="">Select Status</option>
                                <option value="Not Started">Not Started</option>
                                <option value="In Progress">In Progress</option>
                                <option value="Completed">Completed</option>
                                <option value="Waiting on someone else">Waiting on someone else</option>
                                <option value="Deferred">Deferred</option>
                            </Form.Select>
                        </Col>)} */}
                    <Col xs={12} sm={6} lg={3}>
                        <Form.Group className="mx-3" controlId="formBasicOwner">
                            <Form.Label className="form-view-label" htmlFor="formBasicOwner" >
                                Manager Name
                            </Form.Label>
                            <Select
                                name="ownerid"
                                value={option}
                                className="task-select"
                                onChange={handleUsers}
                                options={optionUsers}
                                getOptionValue={(option) => option.value}
                                isClearable

                            // isMulti
                            />



                        </Form.Group>
                    </Col>

                    <Col xs={12} sm={6} lg={3} style={{ marginTop: '28px' }}>
                        <Button onClick={fetchAllData}>Run Report</Button>

                        <Button variant="warning" onClick={() => csvDownload(ReportDataCSV)} className="mx-2">Download CSV</Button>


                    </Col>


                </Row>


                {state.apiname === 'leadactivityreport' && (
                    <div style={{ overflowX: 'auto' }}>
                        <Table striped bordered hover>
                            <thead style={{ backgroundColor: '#1B69B3', color: '#fff' }}>
                                <tr>
                                    <th>Customer Name</th>
                                    <th>Company</th>
                                    <th>Company Group</th>
                                    <th>RM Name</th>
                                    <th>Manager Name</th>
                                    <th>Source</th>
                                    <th>Type of Business</th>
                                    <th>Lead Status</th>
                                    <th>Task Type</th>
                                    <th>Task Title</th>
                                    <th>Task Target Date</th>
                                    <th>Task Start Date</th>
                                    <th>Task End Date</th>
                                    <th>Task Assign Staff</th>
                                    <th>Task Status</th>
                                    <th>Task Remarks</th>


                                </tr>
                            </thead>
                            <tbody>
                                {leadActivityReport?.length > 0 ? leadActivityReport.map((row, index) => {
                                    // const isExpanded = expandedRow === row.id;
                                    return (
                                        <React.Fragment key={index}>
                                            <tr>
                                                <td>{row["Customer Name"]}</td>
                                                <td>{row["Company"]}</td>
                                                <td>{row["Company Group"]}</td>
                                                <td>{row["Relationship Manager Name"]}</td>
                                                <td>{row["Manager Name"]}</td>
                                                <td>{row["Source"]}</td>
                                                <td>{row["Type of Business"]}</td>
                                                <td>{row["Lead Status"]}</td>
                                                <td>{row['Task Type']}</td>
                                                <td>{row['Task Title']}</td>
                                                <td>
                                                    {row['Task Target Date']}
                                                </td>
                                                <td>
                                                    {row['Task Start Date']}
                                                </td>
                                                <td>
                                                    {row['Task End Date']}
                                                </td>
                                                <td>{row['Task Assigned Staff']}</td>
                                                <td>{row['Task Status']}</td>
                                                <td>{row['Task Remark']}</td>

                                            </tr>
                                            {/* {isExpanded && (
                                                <tr>
                                                    <td colSpan={10}>
                                                        <Table style={{ backgroundColor: '#a5c1d587' }}>
                                                            <thead>
                                                                <tr>

                                                                    <th>Task Type</th>
                                                                    <th>Task Title</th>
                                                                    <th>Task Target Date</th>
                                                                    <th>Task Assign Staff</th>
                                                                    <th>Task Status</th>
                                                                    <th>Task Remarks</th>


                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {isExpanded && row["Tasks"] && row["Tasks"].length > 0 && row["Tasks"].map((task, index) => (
                                                                    <tr key={index}>
                                                                        <td>{task['task_type']}</td>
                                                                        <td>{task['task_title']}</td>
                                                                        <td>{moment(task['task_targetdate']).format('DD-MM-YYYY')}</td>
                                                                        <td>{task['owner_name']}</td>
                                                                        <td>{task['task_status']}</td>
                                                                        <td>{task['task_remark']}</td>
                                                                    </tr>
                                                                ))}


                                                            </tbody>
                                                        </Table>
                                                    </td>
                                                </tr>
                                            )} */}
                                        </React.Fragment>
                                    );
                                }) : <tr>
                                    <td colSpan="24" style={{ textAlign: 'center' }}>No records found</td>
                                </tr>}
                            </tbody>
                        </Table>

                    </div>
                )}


                {state.apiname === 'leadsummaryreport' && (
                    <div style={{ overflowX: 'auto' }}>
                        <Table striped bordered hover>
                            <thead style={{ backgroundColor: '#1B69B3', color: '#fff' }}>
                                <tr>
                                    <th>Customer Name</th>
                                    <th>Company Name</th>
                                    <th>Company Group</th>
                                    <th>RM Name</th>
                                    <th>Manager Name</th>
                                    <th>Address</th>
                                    <th>Phone</th>
                                    <th>Source</th>
                                    <th>Type of Business</th>
                                    <th>Lead Contact Date</th>
                                    <th>Lead Creation Date</th>
                                    <th>QRN Number</th>
                                    <th>Type of Policy</th>
                                    <th>Existing Policy Expiry Date</th>
                                    <th>Underwriter Assigned</th>
                                    <th>RFQ Date</th>
                                    <th>Quote Sent Date</th>
                                    <th>Lead Remarks</th>
                                    <th>Lead Status</th>
                                    <th>Case Lost Reason</th>
                                    <th>Number of Meetings</th>
                                    <th>Number of Calls</th>
                                    <th>RAA done ?</th>

                                    <th>Last Modified Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {lead?.length > 0 ? lead.map((row, index) => {
                                    
                                    return (
                                        <React.Fragment key={index}>
                                           {row && (
                                             <tr >
                                             <td>

                                                 &nbsp;{row["Customer Name"] !=='' ? row["Customer Name"] : ""}

                                             </td>
                                             <td>{row["Company Name"]}</td>
                                             <td>{row["Company Group"]}</td>
                                             <td>{row["Relationship Manager Name"]}</td>
                                             <td>{row["Manager Name"]}</td>
                                             <td>{row["Address"]}</td>
                                             <td>{row["Phone"]}</td>
                                             <td>{row["Source"]}</td>
                                             <td>{row["Type of Business"]}</td>
                                             <td>{moment(row["Lead Contact Date"]).format('DD-MM-YYYY')}</td>
                                             <td>{moment(row["Lead Creation Date"]).format('DD-MM-YYYY')}</td>
                                             <td>{row['QRN Number']}</td>
                                             <td>{row['Type of Policy']}</td>
                                             <td>{moment(row['Existing Policy Expiry Date']).format('DD-MM-YYYY')}</td>
                                             <td>
                                                 {row['Underwriter Assigned']}

                                             </td>
                                             <td>{row["RFQ Date"] ? moment(row["RFQ Date"]).format('DD-MM-YYYY') : null}</td>
                                             <td>{row["Quote Sent Date"] ? moment(row["Quote Sent Date"]).format('DD-MM-YYYY') : null}</td>
                                             <td>{row["Lead Remarks"]}</td>
                                             <td>{row["Lead Status"]}</td>
                                             <td>{row["Case Lost Reason"]}</td>
                                             <td>{row["Number of Meetings"]}</td>
                                             <td>{row["Number of Calls"]}</td>
                                             <td>{row["RAA Completed"]}</td>
                                             <td>{moment(row['Last Modified Date']).format('DD-MM-YYYY')}</td>
                                         </tr>
                                           )}
                                            {/* {isExpanded && (
                                                <tr>
                                                    <td colSpan={10}>
                                                        <Table style={{ backgroundColor: '#a5c1d587' }}>
                                                            <thead>
                                                                <tr>
                                                                    <th>QRN Number</th>
                                                                    <th>Type of Policy</th>
                                                                    <th>Existing Policy Expiry Date</th>
                                                                    <th>Underwriter Assigned</th>



                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {isExpanded && row["Products"].length > 0 && row["Products"][0].map((product, index) => (
                                                                    <tr key={index}>
                                                                        <td>{product['Product Quote']}</td>
                                                                        <td>{product['Product Code']} - {product['Product Name']}</td>
                                                                        <td>{moment(product['Policy Expiry Date']).format('DD-MM-YYYY')}</td>
                                                                        <td>
                                                                            {product['Under Writer Manager'] ?
                                                                                (() => {
                                                                                    try {
                                                                                        const manager = JSON.parse(product['Under Writer Manager']);
                                                                                        return manager.username;
                                                                                    } catch (error) {
                                                                                        console.error('Error parsing JSON:', error);
                                                                                        return '';
                                                                                    }
                                                                                })()
                                                                                : ''}
                                                                        </td>
                                                                    </tr>
                                                                ))}

                                                            </tbody>
                                                        </Table>
                                                    </td>
                                                </tr>
                                            )} */}
                                        </React.Fragment>
                                    );
                                }) : <tr>
                                    <td colSpan="24" style={{ textAlign: 'center' }}>No records found</td>
                                </tr>}
                            </tbody>
                        </Table>

                    </div>
                )}


                {reports.length > 0 && state.apiname === 'meetingreport' &&
                    <DatatableWrapper body={reports} headers={headers} paginationOptionsProps={{
                        initialState: {
                            rowsPerPage: 10,
                            options: [5, 10, 15, 20]
                        }
                    }}>
                        <Row className="mb-4">
                            <Col xs={12} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                                {/* <Filter /> */}
                            </Col>
                            <Col xs={12} sm={6} lg={4} className="d-flex flex-col justify-content-start align-items-start">
                                {/* <PaginationOptions labels={labels} /> */}
                            </Col>
                        </Row>
                        <Table striped className="data-table" responsive="sm">
                            <TableHeader />
                            <TableBody />
                        </Table>
                        <Pagination />
                    </DatatableWrapper>
                }
            </Col>
        </Row>
    );
}

export default ViewTaskReports;
