import logo from "./logo.svg";

import "./App.css";
import "./Sidebar.css"
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import Home from "./components/Home";
import { AccordionCollapse, Container } from "react-bootstrap";
import Footer from "./components/Footer";
import Sidebar from "./components/Sidebar";
import React, { useEffect, useState } from "react";
import Alert from 'react-bootstrap/Alert';

import LeadList from "./components/LeadList";
import LeadView from "./components/LeadView";
import LeadEdit from "./components/LeadEdit";

import AccountList from "./components/AccountList";
import AccountView from "./components/AccountView";
import AccountEdit from "./components/AccountEdit";
import ContactList from "./components/ContactList";
import ContactView from "./components/ContactView";
import ContactEdit from "./components/ContactEdit";
import ContactCreate from "./components/ContactCreate";

import MiniSidebar from "./components/MiniSidebar";
import * as constants from './constants/CONSTANT';
import ChangePassword from "./components/ChangePassword";
import ReportView from "./components/ReportView";
import ReportList from "./components/ReportList";
import Calendar from "./components/Calendar";
import ReportBuilder from "./components/ReportBuilder";
import ReportEdit from "./components/ReportEdit";

import PubSub from 'pubsub-js';
import { Toast, ToastContainer } from "react-bootstrap";
import UserList from "./components/UserList";
import UserEdit from "./components/UserEdit";
import UserView from "./components/UserView";
import EditProfile from "./components/EditProfile";
import UserTracking from "./components/UserTracking";
import BusinessList from "./components/BusinessList";
import BusinessView from "./components/BusinessView";
import BusinessEdit from "./components/BusinessEdit";
import Notification from "./components/Notification";
import BackupList from "./components/BackupList";
import jwt_decode from "jwt-decode";
import io from "socket.io-client";
import Main from "./components/layout/Main";
import ProductList from "./components/ProductList";
import ProductEdit from "./components/ProductEdit";
import ProductView from "./components/ProductView";
import PricebookEdit from "./components/PricebookEdit";
import IncidentList from "./components/IncidentList";
import IncidentView from "./components/IncidentView";
import IncidentEdit from "./components/IncidentEdit";
import DashboardList from "./components/DashboardList";
import Dashboard from "./components/Dashboard";
import DashboardView from "./components/DashboardView";
import DashboardEdit from "./components/DashboardEdit";
import TaskReports from "./components/TaskReports";
import ViewTaskReports from "./components/ViewTaskReports";
import MeetingReport from "./components/MeetingReport";



function App() {
  const [modalShow, setModalShow] = useState(false);
  const [title, setTitle] = useState('Confirmation');
  const [message, setMessage] = useState('');
  const [variant, setVariant] = useState('');
  const [userInfo, setUserInfo] = useState({});
  const [permissions, setPermissions] = useState();
  const [connectedSocket, setConnectedSocket] = useState();

  const mySubscriber = (msg, data) => {

    switch (msg) {
      case 'RECORD_SAVED_TOAST':
        setTitle(data.title);
        setMessage(data.message);
        setModalShow(true);
        setVariant('success')
        //////('mytopic: RECORD_SAVED_TOAST'); // add your desired log message
        break;
      case 'RECORD_ERROR_TOAST':
        setTitle(data.title);
        setMessage(data.message);
        setModalShow(true);
        setVariant('danger')
        //////('mytopic: RECORD_ERROR_TOAST'); // add your desired log message
        break;
      default:
        //////('mytopic: default'); // add your desired log message
        break;
    }
  };
  useEffect(() => {
    //dispatch(fetchAccounts());
    PubSub.subscribe('RECORD_SAVED_TOAST', mySubscriber);
    PubSub.subscribe('RECORD_ERROR_TOAST', mySubscriber);
    try {
      if (localStorage.getItem('token')) {
        let user = jwt_decode(localStorage.getItem('token'));
        setUserInfo(user);

        var perm = user.permissions.map(function (obj) {
          return obj.name;
        }).join(';')
        setPermissions(perm);


        //socket

        // let socket = io(ENDPOINT, {     // note changed URL here
        //   path: '/socket.io',
        //   transports: ['websocket'],
        // });
        // socket.on("connect_error", (err) => {
        //   //(`connect_error due to`,err);
        // });
        // //(socket);
        // socket.emit("setup", user);
        // socket.on("connect", (msg) => {
        //   //('connected client')
        //   setConnectedSocket(socket);
        // })




      }
    } catch (error) {
      //('error' , error)
    }





  }, []);

  return (
    <>
      <ToastContainer className="p-3" position='top-center'>
        <Toast show={modalShow} onClose={() => setModalShow(false)} delay={3000} bg={variant} className="text-white" autohide>

          {variant === 'success' ?
            <div className="p-1 m-1" style={{ backgroundColor: '#198754', color: 'white' }}  >
              <i className="fa-regular fa-circle-check text-white mx-2"></i>
              <strong className="me-auto">{title}</strong>
              <i className="fa-solid fa-xmark text-white float-right" style={{ float: 'right' }} role='button' onClick={() => setModalShow(false)}></i>
            </div>
            :
            <div className="p-1 m-1" style={{ backgroundColor: '#DC3545', color: 'white' }}  >
              <i className="fa-regular fa-circle-check text-white mx-2"></i>
              <strong className="me-auto">{title}</strong>
              <i className="fa-solid fa-xmark text-white float-right" style={{ float: 'right' }} role='button' onClick={() => setModalShow(false)}></i>
            </div>
          }

          <Toast.Body>{message instanceof Array ? message.map((item) => {
            return <span>{item.msg}</span>
          }) : message instanceof Object ? <span>{message.detail}</span> : <span>{message}</span>}</Toast.Body>
        </Toast>
      </ToastContainer>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Main socket={connectedSocket} />}>
            <Route
              index
              element={

                <Home />

              }
            />
            <Route
              path="leads/:id"
              element={

                <LeadView />

              }
            />

            <Route
              path="taskreports"
              element={

                <TaskReports />

              }
            />

            <Route
              path="taskreports/:reportname"
              element={

               <ViewTaskReports/>

              }
            />
             

            {/******** Show All Leadss *******/}
            <Route
              path="/leads"
              element={

                <LeadList />

              }
            />
            {/******** Show an Lead By Id *******/}




            <Route
              path="/maintanance"
              element={
                <>
                  <div className="wrapper">

                    <div id="content">

                      <Notification />
                    </div>
                  </div>
                </>
              }
            />

            {/******** Create Lead  *******/}
            <Route
              path="leads/e"
              element={

                <LeadEdit />

              }
            />
            {/******** Update Lead  *******/}
            <Route
              path="leads/:id/e"
              element={

                <LeadEdit />

              }
            />


            {/**** Business Section */}

            <Route
              path="/business"
              element={

                <BusinessList />

              }
            />
            {/******** Show an Lead By Id *******/}
            <Route
              path="business/:id"
              element={

                <BusinessView />

              }
            />

            {/******** Create Lead  *******/}
            <Route
              path="business/e"
              element={

                <LeadEdit />

              }
            />
            {/******** Update Lead  *******/}
            <Route
              path="business/:id/e"
              element={

                <BusinessEdit />

              }
            />

            {/**** Users Section */}

            <Route
              path="/users"
              element={

                <UserList />
                //     : <Alert error-alert variant='danger' className="alert error-alert">
                //       <i class="fa-solid fa-triangle-exclamation mx-2"></i>You haven’t have any access
                //     </Alert>

                // }
              }
            />

            <Route
              path="/meetings"
              element={

                <Calendar />

              }
            />

            <Route
              path="/meetings/:today"
              element={

                <Calendar />

              }
            />


            {/******** Edit Profile *******/}
            <Route
              path="/myprofile"
              element={

                <EditProfile />

              }
            />


            {/*  Create Lead  */}

            <Route
              path="users/e"
              element={

                <UserEdit />

              }
            />
            {/******** Update Users  *******/}
            <Route
              path="users/:id/e"
              element={

                <UserEdit />

              }
            />


            {/******** Show an User By Id *******/}
            <Route
              path="users/:id"
              element={

                <UserView />

              }
            />

            {/******** Show an Lead By Id *******/}
            <Route
              path="users/:id"
              element={

                <LeadView />

              }
            />

            {/******** Create Lead  *******/}
            <Route
              path="users/e"
              element={

                <LeadEdit />

              }
            />
            {/******** Update Lead  *******/}
            <Route
              path="users/:id/e"
              element={

                <LeadEdit />

              }
            />
            {/******** Creat Account *******/}
            <Route
              path="/accounts/e"
              element={

                permissions && (permissions.indexOf(constants.MODIFY_ALL) >= 0) ?
                  <AccountEdit /> : 'You have no permission to access account'

              }
            />
            {/******** Show All Accounts *******/}
            <Route
              path="/accounts"
              element={


                permissions && (permissions.indexOf(constants.VIEW_ACCOUNT) >= 0
                  || permissions.indexOf(constants.MODIFY_ALL) >= 0) ?
                  <AccountList />
                  : <Alert error-alert variant='danger' className="alert error-alert">
                    <i class="fa-solid fa-triangle-exclamation mx-2"></i>You haven’t have any access
                  </Alert>

              }
            />


            {/******** Show an Account By Id *******/}
            <Route
              path="accounts/:id"
              element={


                <AccountView />

              }
            />
            {/******** Edit Account By Id *******/}
            <Route
              path="accounts/:id/e"
              element={

                <AccountEdit />

              }
            />

            {/******** Crate a Contact *******/}
            <Route
              path="/contacts/e"
              element={

                permissions && (permissions.indexOf(constants.MODIFY_ALL) >= 0) ?
                  <ContactEdit /> : 'You have no permission to access account'

              }
            />
            {/******** Show All Contacts *******/}
            <Route
              path="/contacts"
              element={

                permissions && (permissions.indexOf(constants.VIEW_CONTACT) >= 0
                  || permissions.indexOf(constants.MODIFY_ALL) >= 0) ?
                  <ContactList /> : 'You have No permission for contact'

              }
            />
            {/******** Show a Contact By Id *******/}
            <Route
              path="contacts/:id"
              element={

                <ContactView />

              }
            />
            {/******** Edit Contact By Id *******/}
            <Route
              path="contacts/:id/e"
              element={

                <ContactEdit />

              }
            />

            <Route
              path="/changepassword"
              element={

                <ChangePassword />

              }
            />
            <Route path="reports/:id" element={

              <ReportView />

            }
            />
            <Route
              path="/reports"
              element={

                <ReportList />

              }
            />


            <Route
              path="/usertracking"
              element={

                <UserTracking />

              }
            />

            <Route
              path="/backup"
              element={

                <BackupList />

              }
            />

            {/*Start updated by Pooja  Vaishnav */}
            {/******** Create Product  *******/}
            <Route
              path="/products/e"
              element={
                <ProductEdit />

              }
            />
            {/******** Show All Products *******/}
            <Route
              path="/products"
              element={
                <ProductList />
              }
            />
            {/******** Show a product by id  *******/}
            <Route
              path="products/:id"
              element={

                <ProductView />

              }
            />
            {/******** Edit product By Id *******/}
            <Route
              path="products/:id/e"
              element={

                <ProductEdit />

              }
            />
            {/******** Create Incident  *******/}
            <Route
              path="incidents/e"
              element={

                <IncidentEdit />

              }
            />

            {/******** Update Incident  *******/}
            <Route
              path="incidents/:id/e"
              element={

                <IncidentEdit />

              }
            />
            {/******** Show All Incident view *******/}
            <Route
              path="incidents/:id"
              element={

                <IncidentView />

              }
            />


            {/******** Show All Incident *******/}
            <Route
              path="/incidents"
              element={

                <IncidentList />

              }
            />
            <Route path="reports/:id"
              element={
                <>
                  <div className="wrapper">
                    {/* <Sidebar /> */}
                    <div id="content">
                      <ReportView />
                    </div>
                  </div>
                </>
              }
            />
            <Route
              path="/reports"
              element={
                <>
                  <div className="wrapper">
                    {/* <Sidebar /> */}
                    <div id="content">
                      <ReportList />
                    </div>
                  </div>
                </>
              }
            />

            <Route
              path="/reportedit/:id"
              element={
                <>
                  <div className="wrapper">
                    {/* <Sidebar /> */}
                    <div id="content">

                      <ReportEdit />
                    </div>
                  </div>
                </>
              }
            />

            <Route
              path="/reports/e"
              element={
                <>
                  <div className="wrapper">
                    {/* <Sidebar /> */}
                    <div id="content">

                      <ReportBuilder />
                    </div>
                  </div>
                </>
              }
            />

            <Route
              path="/dashboard"
              element={
                <Dashboard />
              }
            />



            <Route
              path="/dashboards"
              element={
                <DashboardList />
              }
            />
            <Route path="dashboards/:id" element={<DashboardView />} />
            <Route path="dashboards/:id" element={<DashboardView />} />
            <Route path="dashboards/:id/e" element={<DashboardEdit />}></Route>


          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
